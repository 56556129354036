import React, { Component } from "react";
import styled, { css } from "styled-components";
import Modal from "./Modal";
import { Button } from "./ConfirmationModal";
import { Order, OrderProduct, OrderProductPrepQty, OrderState, OrderStatus } from "../../model/Order";
import EventSystem from "../../utils/EventSystem";
import noImage from "../../assets/nincs_kep.png";
import { toast } from "react-toastify";
import { OrdersAPI } from "../../utils/api/OrdersAPI";
import { FaPlus } from "react-icons/all";
import { Input as InputOrg } from "../../pages/LoginPage";
import Orders from "../home/Orders";
import BarCodeScannerComponent from "../BarcodeScannerComponent";

const SingleLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  justify-content: flex-start;
`;

const OrderProducts = styled.div`
  width: 100%;
  height: 50vh;
  overflow: auto;
  position: relative;
`;

const OrderProductDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  user-select: none;

  width: 100%;
  padding: 5px 7px;
  border-bottom: 1px solid #898989;
  background-color: ${({ color }) => color ? color : "white"};

  & > sku {
    width: 15%;
    overflow: hidden;
  }

  & > name {
    width: 55%;
    overflow: hidden;
  }

  & > qty {
    width: 8%;
    text-align: center;
    overflow: hidden;
  }

  & > buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 15%;
    overflow: hidden;
  }

  & > buttons {
    & > svg {
      width: fit-content;
      height: fit-content;
      color: #222222;
      padding: 3px;
      border-radius: 3px;
      font-size: 12px;
      transition: color 200ms ease-in-out;

      &:hover, &:focus {
        cursor: pointer;
        color: #8d8d8d;
      }
    }
  }

  ${({ header }) => header && css`
    position: sticky;
    top: 0;
    box-shadow: 3px 0 4px 1px #747474;
    font-weight: bold;
    border-top: 1px solid #393939;
    background-color: #bababa;

    & > qty {
      overflow: visible;
    }
  `};

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    font-size: 13px;

    & > sku {
      width: 32%;
    }

    & > name {
      width: 100%;
    }

    & > qty {
      width: 32%;
    }

    & > buttons {
      width: 32%;
    }
  }
`;

const ListLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
`;

const BigItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const ItemTitle = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 14pt;
  text-align: center;
`;

const ItemPictureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-height: 70%;
  min-height: 30vh;
  user-select: none;
  margin: 8px 3px 5px 3px;
  box-shadow: 0 0 4px 1px #bababa;
  border-radius: 4px;
  padding: 7px;

  & > img {
    max-width: 100%;
    max-height: 50vh;
  }

  @media screen and (max-width: 1000px) {
    & > img {
      max-height: 40vh;
    }
  }
`;

const ItemDetails = styled.div`
  font-size: 11pt;
  text-align: center;
  padding: 0 5px;
  border-radius: 4px;
  ${({ done }) => done === true && css`
    background-color: rgba(2, 149, 2, 0.66);
  `}
  ${({ started }) => started === true && css`
    background-color: rgba(255, 165, 0, 0.66);
  `}
`;

const ItemButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 0 0;
`;

const PlusButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > svg {
    transition: transform 200ms ease-in-out;

    &:hover, &:focus {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
`;

const Input = styled(InputOrg)`
  margin: 0 5px;
  padding: 4px 5px;
  border-radius: 4px;
  height: 20px;
  width: 50px;
  text-align: center;
`;

const shelfColumnPairs: { labels: String[], value: number }[] = [
  { labels: ["A", "B"], value: 0 },
  { labels: ["C", "D"], value: 1 },
  { labels: ["E", "F"], value: 2 },
  { labels: ["G", "H"], value: 3 },
  { labels: ["I", "J"], value: 4 },
  { labels: ["K", "L"], value: 5 },
  { labels: ["M", "N"], value: 6 },
  { labels: ["O", "P"], value: 7 },
  { labels: ["Q", "R"], value: 8 },
  { labels: ["S", "T"], value: 9 },
  { labels: ["U", "V"], value: 10 },
  { labels: ["W", "X"], value: 11 },
  { labels: ["Y", "Z"], value: 12 }
];

export default class OrderDetailsModal extends Component {
  state: {
    showModal: boolean,
    showBarcodeScanner: boolean,
    order: Order,
    orderStates: Array<OrderStatus>,
    preparation: boolean,
    control: boolean,
    showingOrderProduct: number,
    collectedProducts: {
      [keys: string]: number
    },
    editQty: number,
    editQtys: {
      [keys: string]: number
    },
    listLayout: boolean,
  } = {
    showModal: false,
    showBarcodeScanner: false,
    order: undefined,
    orderStates: [],
    preparation: false,
    control: false,
    showingOrderProduct: 0,
    collectedProducts: {},
    editQtys: {},
    editQty: 1,
    listLayout: false
  };

  handleBarcodeScanned(value: CustomEvent) {
    //value.detail should be the sku
    if (!value || !this.state.showModal) {
      return;
    }

    let scannedGTIN = value.detail;

    this.setState({ showBarcodeScanner: false });

    let selectedOrderProduct = this.state.listLayout ? undefined : this.state.order.orderProducts[this.state.showingOrderProduct];

    if (selectedOrderProduct && selectedOrderProduct.gtin === scannedGTIN) {
      this.handlePlusButton(selectedOrderProduct, 1, true);
      return;
    }

    let orderProduct: OrderProduct;
    for (let op of this.state.order.orderProducts) {
      if (op.gtin === scannedGTIN) {
        orderProduct = op;
        if (this.state.collectedProducts[op.sku] < op.stock1) {
          break;
        }
      }
    }

    if (!orderProduct) {
      toast("Vondalkód nem található! (" + value.detail + ")");
      EventSystem.publish(EventSystem.events.playSound, ({ error: true }));
      return;
    }

    this.handlePlusButton(orderProduct, 1, true);
  }

  componentWillUnmount() {
    document.removeEventListener("barcodeScanned", v => this.handleBarcodeScanned(v));
  }

  componentDidMount() {
    document.addEventListener("barcodeScanned", v => this.handleBarcodeScanned(v));

    EventSystem.subscribe(EventSystem.events.showOrderDetails,
      ({ order, orderStatuses, preparation, control, orderProductPrepQtyList }) => {
        let editQtys: { [keys: string]: number } = {};
        let collectedProducts: { [keys: string]: number } = {};
        order.orderProducts.forEach(op => {
          collectedProducts[op.sku] = 0;
          editQtys[op.sku] = 1;
        });

        if (orderProductPrepQtyList)
          orderProductPrepQtyList.forEach((op: OrderProductPrepQty) => collectedProducts[op.orderProductSKU] = op.qty);

        order.orderProducts.sort((a, b) => {
          let col1Name: string = a.modelNumber?.substr(0, 1) ?? "A";
          let col2Name: string = b.modelNumber?.substr(0, 1) ?? "A";
          let num1: string = a.modelNumber.substring(1, a.modelNumber.length) ?? "0";
          let num2: string = b.modelNumber.substring(1, a.modelNumber.length) ?? "0";

          let col1Value = shelfColumnPairs.find(v => v.labels.includes(col1Name))?.value ?? 0;
          let col2Value = shelfColumnPairs.find(v => v.labels.includes(col2Name))?.value ?? 0;

          if (col1Value !== col2Value) {
            return col1Value - col2Value;
          } else {
            if (!isNaN(parseInt(num1)) && !isNaN(parseInt(num2))) {
              return num1 - num2;
            } else {
              return num1.localeCompare(num2);
            }
          }
        });

        this.setState({
          order,
          orderStates: orderStatuses,
          showModal: true,
          showBarcodeScanner: false,
          preparation,
          control,
          showingOrderProduct: 0,
          collectedProducts,
          editQtys,
          editQty: 1,
          listLayout: control === true
        });
      });
    EventSystem.subscribe(EventSystem.events.orderStatusAdded, ({ orderId, newStatus }) => {
      if (this.state.showModal && orderId === this.state.order.id) {
        let orderStates: Array<OrderStatus> = [];
        for (let orderState of this.state.orderStates)
          orderStates.push(orderState);

        orderStates.push(newStatus);

        this.setState({ orderStates });
      }
    });
  }

  render() {
    // if (this.state.order && this.state.order.orderProducts) {
    //   this.state.order.orderProducts.sort((a, b) => {
    //     if (a.sku < b.sku)
    //       return -1;
    //     else if (a.sku > b.sku)
    //       return +1;
    //     else
    //       return 0;
    //   });
    // }

    return (
      <>
        <Modal show={this.state.showModal} size={"lg"} onEscapeKeyDown={() => this.handleEscapeKeyDown()}>
          <Modal.Body>
            {!this.state.listLayout && this.state.showModal &&
            <>
              {(() => {
                let orderProduct = this.state.order ? this.state.order.orderProducts[this.state.showingOrderProduct] : undefined;
                let httpImage = "";
                if (orderProduct.mainImage) {
                  let httpImageURL = new URL(orderProduct.mainImage);
                  httpImageURL.protocol = "http";
                  let h = httpImageURL.host;
                  h = h.replace("\\.sandbox\\.", ".");
                  httpImageURL.host = h;
                  httpImage = httpImageURL.toString();
                }
                return (
                  <>
                    <Modal.Title>
                      {this.state.order.innerId} {this.state.preparation ? " | Összeszedés - " : " | Csomagolás - "} {this.state.showingOrderProduct + 1}/{this.state.order.orderProducts.length} tétel
                    </Modal.Title>
                    <SingleLayout>
                      <BigItem>
                        <ItemTitle>{orderProduct.sku} | {orderProduct.modelNumber}</ItemTitle>
                        <ItemPictureWrapper>
                          <img alt={"termékkép"} src={orderProduct.mainImage ? orderProduct.mainImage : noImage}
                               onError={e => e.target.src = httpImage}
                          />
                        </ItemPictureWrapper>
                        <ItemDetails
                          started={this.state.collectedProducts[orderProduct.sku] > 0 && this.state.collectedProducts[orderProduct.sku] < orderProduct.stock1}
                          done={this.state.collectedProducts[orderProduct.sku] === orderProduct.stock1}>
                          <b>{orderProduct.name}</b><br/>
                          {this.state.collectedProducts[orderProduct.sku]}/{orderProduct.stock1} db
                        </ItemDetails>
                      </BigItem>
                      <ItemButtonsWrapper>
                        <Button onClick={() => this.handlePrevClick()}>Előző</Button>
                        <PlusButton>
                          <Input
                            value={this.state.editQty}
                            type="number"
                            autoComplete={"off"}
                            placeholder="Me."
                            onChange={(e) => this.setState({ editQty: parseInt(e.target.value) })}
                          />
                          <FaPlus onClick={() => this.handlePlusButton(orderProduct, this.state.editQty, false)}/>
                        </PlusButton>
                        <Button onClick={() => this.handleSkipClick()}>Kihagyás</Button>
                      </ItemButtonsWrapper>
                    </SingleLayout>
                  </>
                );
              })()}
            </>
            }
            {this.state.listLayout && this.state.showModal &&
            <>
              <Modal.Title>
                Csomagolás - {this.state.order.orderProducts.length} tétel
              </Modal.Title>
              <ListLayout>
                <OrderProducts>
                  <OrderProductDiv header={true}>
                    <name>Terméknév</name>
                    <sku>Cikkszám</sku>
                    <qty>Darabszám</qty>
                    <buttons/>
                  </OrderProductDiv>
                  {this.state.order.orderProducts.map((op, i) => {
                    let color = "white";

                    let collectedProducts = this.state.collectedProducts;
                    let sku = op.sku;

                    if (collectedProducts[sku] === op.stock1) {
                      color = "rgb(48,227,6)";
                    } else if (collectedProducts[sku] > 0 && collectedProducts[sku] < op.stock1) {
                      color = "rgb(246,201,62)";
                    }

                    return (
                      <OrderProductDiv key={i} color={color}>
                        <name>{op.name}</name>
                        <sku>{op.sku}</sku>
                        <qty>{this.state.collectedProducts[op.sku]}/{op.stock1} db</qty>
                        <buttons>
                          <Input
                            value={this.state.editQtys[op.sku]}
                            type="number"
                            autoComplete={"off"}
                            placeholder="Me."
                            onChange={(e) => this.handleQtysChanged(op, e.target.value)}
                          />
                          <FaPlus
                            onClick={() => this.handlePlusButton(op, parseInt(this.state.editQtys[op.sku]), false)}/>
                        </buttons>
                      </OrderProductDiv>
                    );
                  })}
                </OrderProducts>
              </ListLayout>
            </>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setState({ showBarcodeScanner: true })}>
              Vonalkód olvasó
            </Button>
            <Button onClick={() => this.handleEscapeKeyDown()}>
              Mégsem
            </Button>
            <Button onClick={() => this.handleDoneClicked()}>
              {this.state.preparation && "Csomagolás befejezése"}
              {this.state.control && "Ellenőrzés befejezése"}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showBarcodeScanner} size={"lg"}
               onEscapeKeyDown={() => this.setState({ showBarcodeScanner: false })}>
          <Modal.Body>
            <Modal.Title>
              Vonalkód beolvasása
            </Modal.Title>
            {this.state.showBarcodeScanner &&
            <BarCodeScannerComponent/>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setState({ showBarcodeScanner: false })}>
              Mégsem
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  handleEscapeKeyDown() {
    if (!this.state.collectedProducts || Object.keys(this.state.collectedProducts).length <= 0) {
      this.setState({ showModal: false });
      return;
    }
    EventSystem.publish(EventSystem.events.show_confirmation_modal, {
      title: "Kilépés",
      text: "Biztosan kilépsz a csomagolásból? Az eddigi bevitt adatok nem mentődnek el!",
      yesText: "Igen",
      noText: "Nem",
      proceedCb: () => this.setState({ showModal: false })
    });
  }

  handlePrevClick() {
    let showingOrderProduct = this.state.showingOrderProduct - 1;
    if (showingOrderProduct < 0)
      showingOrderProduct = 0;
    this.setState({ showingOrderProduct });
  }

  checkReadiness() {
    if (!this.state.order || !this.state.showModal)
      return false;

    for (let orderProduct: OrderProduct of this.state.order.orderProducts) {
      if (this.state.collectedProducts[orderProduct.sku] < orderProduct.stock1)
        return false;
    }

    return true;
  }

  checkEmptiness() {
    if (!this.state.order || !this.state.showModal)
      return false;

    for (let orderProduct: OrderProduct of this.state.order.orderProducts) {
      if (this.state.collectedProducts[orderProduct.sku] > 0)
        return false;
    }

    return true;
  }

  handlePlusButton(orderProduct: OrderProduct, qty: number = 1, scan: boolean = false) {
    if (!orderProduct) {
      if (scan)
        EventSystem.publish(EventSystem.events.playSound, ({ error: true }));
      // orderProduct = this.state.order.orderProducts[this.state.showingOrderProduct];
      return;
    }

    try {
      if (
        !this.state.listLayout &&
        orderProduct.sku !== this.state.order.orderProducts[this.state.showingOrderProduct].sku
      ) {
        toast("A hozzáadott termék nem az aktuális!");
        if (scan) {
          EventSystem.publish(EventSystem.events.playSound, ({ error: true }));
        }
        return;
      }
    } catch (e) {
      return;
    }

    qty = parseInt(qty);

    let sku = orderProduct.sku;

    let collectedProducts = this.state.collectedProducts;
    if (collectedProducts[sku] + qty > orderProduct.stock1) {
      EventSystem.publish(EventSystem.events.show_confirmation_modal, {
        title: "Mennyiség!",
        text: (
          <div>
            Ebből a termékből már elegendő mennyiség van a csomagban!<br/>
            <br/>
            <b>Terméknév:</b> {orderProduct.name}<br/>
            <b>Cikkszám:</b> {orderProduct.sku}<br/>
            <b>Vonalkód:</b> {orderProduct.gtin}<br/>
            <b>Mennyiség:</b> {orderProduct.stock1}db
          </div>
        ),
        yesText: "Ok",
        noText: ""
        // cancelCb: ()=>{},
        // proceedCb: ()=>{}
      });
      toast("Már van elegendő termék a csomagban!");
      if (scan) {
        EventSystem.publish(EventSystem.events.playSound, ({ error: true }));
      }
      return;
    }

    let currentState: OrderStatus = Orders.findLastState(this.state.order.id, this.state.orderStates);
    if (!(this.state.preparation && currentState.orderState.value === OrderState.WAITING_FOR_CONFIRMATION)) {
      let newState: number = OrderState.getNextState(currentState.orderState.value);

      if (this.checkEmptiness() && (currentState.orderState.value === OrderState.NEW || currentState.orderState.value === OrderState.WAITING_FOR_CONFIRMATION)) {
        OrdersAPI.changeOrderStatus(this.state.order.id, newState, undefined, res => {
          let resTyped: {
            error: number,
            type: number,
            orderStatus: OrderStatus,
          } = res;

          if (resTyped.error === 0) {
            let orderStatus = res.orderStatus;
            orderStatus.date = new Date(orderStatus.date.value);
            EventSystem.publish(EventSystem.events.orderStatusAdded, {
              orderId: this.state.order.id,
              newStatus: orderStatus
            });
          }
        });
      }
    }

    if (!collectedProducts[sku])
      collectedProducts[sku] = 0;
    collectedProducts[sku] = collectedProducts[sku] + qty;
    this.setState({ collectedProducts, editQty: 1 });

    if (collectedProducts[sku] === orderProduct.stock1) {
      this.handleSkipClick();
    }
    if (scan) {
      EventSystem.publish(EventSystem.events.playSound);
    }
  }

  handleSkipClick() {
    let showingOrderProduct = this.state.showingOrderProduct + 1;
    if (showingOrderProduct >= this.state.order.orderProducts.length)
      this.setState({ listLayout: true });
    else
      this.setState({ showingOrderProduct });
  }

  closeModal() {
    if (this.state.preparation) {
      let orderProductPrepQtyList: [{ sku: string, qty: number }] = [];
      for (let sku in this.state.collectedProducts) {
        if (this.state.collectedProducts[sku] > 0)
          orderProductPrepQtyList.push({ sku, qty: this.state.collectedProducts[sku] });
      }

      OrdersAPI.changeOrderStatus(this.state.order.id, OrderState.WAITING_FOR_CONFIRMATION, orderProductPrepQtyList, res => {
        let resTyped: {
          error: number,
          type: number,
          orderStatus: OrderStatus,
        } = res;

        if (resTyped.error === 0) {
          EventSystem.publish(EventSystem.events.show_confirmation_modal, {
            title: "Összepakolás kész",
            text: "Összepakolás kész. Következő lépés a csomagolás!",
            yesText: "Ok",
            noText: "",
            proceedCb: () => this.setState({ showModal: false }),
            cancelCb: () => this.setState({ showModal: false })
          });
          let orderStatus = res.orderStatus;
          orderStatus.date = new Date(orderStatus.date.value);
          EventSystem.publish(EventSystem.events.orderStatusAdded, {
            orderId: this.state.order.id,
            newStatus: orderStatus,
            orderProductPrepQtyList
          });
        }
      });
    } else if (this.state.control) {
      OrdersAPI.changeOrderStatus(this.state.order.id, OrderState.READY, undefined, res => {
        let resTyped: {
          error: number,
          type: number,
          orderStatus: OrderStatus,
        } = res;

        if (resTyped.error === 0) {
          EventSystem.publish(EventSystem.events.show_confirmation_modal, {
            title: "Csomagolás kész",
            text: "Csomagolás kész. A rendelés állapota " + OrderState.getName(OrderState.READY) + "!",
            yesText: "Ok",
            noText: "",
            proceedCb: () => this.setState({ showModal: false }),
            cancelCb: () => this.setState({ showModal: false })
          });
          let orderStatus = res.orderStatus;
          orderStatus.date = new Date(orderStatus.date.value);
          EventSystem.publish(EventSystem.events.orderStatusAdded, {
            orderId: this.state.order.id,
            newStatus: orderStatus
          });
        }
      });
    }
  }

  handleDoneClicked() {
    if (!this.checkReadiness()) {
      EventSystem.publish(EventSystem.events.show_confirmation_modal, {
        title: "Biztos kilépsz?",
        text: "Még hiányzik a rendelésből néhány tétel! Biztosan kilépsz?",
        yesText: "Igen",
        noText: "Mégsem",
        proceedCb: () => {
          this.closeModal();
        }
      });
    } else {
      this.closeModal();
    }
  }

  handleQtysChanged(op: OrderProduct, value: number) {
    let editQtys: { [keys: string]: number } = [];
    for (let sku in this.state.editQtys) {
      if (sku === op.sku) {
        if (value <= 0)
          value = 1;
        editQtys[sku] = value;
      } else {
        editQtys[sku] = this.state.editQtys[sku];
      }
    }
    this.setState({ editQtys });
  }
}
