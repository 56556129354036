import APICaller from "../APICaller";
import { Order, OrderProductPrepQty, OrderStatus } from "../../model/Order";

function getOrders(dateMin: Date, dateMax: Date, cb: (res: { error: number, type: number, orders: Order[], orderStatuses: OrderStatus[], orderProductPrepQtyList: OrderProductPrepQty[] })=> {}) {
  APICaller.ownFetch(false, "POST", "/orders", {
      dateMin: dateMin.toISOString(),
      dateMax: dateMax.toISOString()
    },
    (data) => {
      let res = JSON.parse(data);
      if (cb)
        cb(res);
    }
  );
}

function changeOrderStatus(orderId: string, newOrderState: number, orderProductPrepQtyList: [{ sku: string, qty: number }] | undefined, cb) {
  APICaller.ownFetch(false, "PUT", "/order/status", {
      orderId, newOrderState,orderProductPrepQtyList
    },
    (data) => {
      let res = JSON.parse(data);
      if (cb)
        cb(res);
    }
  );
}

export const OrdersAPI = {
  getOrders,
  changeOrderStatus
};
