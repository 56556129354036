import Config from "./Config";
import { toast } from "react-toastify";
import EventSystem from "./EventSystem";

let baseURL = Config.backendURL;

let lastServerIsNotAvailableToast;

function ownFetch(quite, method, url, params, callback) {
  console.log(baseURL + url + "::" + method + " called");

  let init = {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=UTF-8"
    },
    credentials: "include",
    withCredentials: true
  };
  if (window.localStorage.getItem("usertoken") != null) {
    params.usertoken = window.localStorage.getItem("usertoken");
  }

  if (method !== "GET") {
    init.body = JSON.stringify(params);
  }

  fetch(baseURL + url, init)
    .then((res) => {
      return res.text();
    })
    .then((res) => {
      try {
        let result = JSON.parse(res);
        if (!quite) {
          if (result.error === 1000) {
            toast("Kommunikációs hiba.");
          } else if (result.error === 1001) {
            toast("Valami hiba törént :(");
          } else if (result.error === 1002) {
            toast("Hibás szerverkomminikáció");
          } else if (result.error === 1003) {
            toast("Kérés nem engedélyezett.");
          } else if (result.error === 1004) {
            toast("Kérem jelentkezzen be!");
            EventSystem.publish(EventSystem.events.authentication_changed, false);
          } else if (result.error === 1005) {
            toast("Adatbázis hiba!");
          } else if (result.error === 1006) {
            toast("Shoprenter API kapcsolódási hiba!");
          } else if (result.error === 1007) {
            toast("Rendelés nem található!");
          } else if (result.error === 1008) {
            toast("Hiba!");
          }
        }
      } catch (e) {
        console.log(e.message);
      }
      try {
        if (callback)
          callback(res);
      } catch (e) {
        console.log(e.message);
        if (!quite)
          toast("Ismeretlen hiba.");
      }
    })
    .catch((reason) => {
      console.log(reason);
      if (!lastServerIsNotAvailableToast || Math.abs(new Date() - lastServerIsNotAvailableToast) >= 3500) {
        lastServerIsNotAvailableToast = new Date();
        toast("A szerver nem elérhető.");
      }
    });
}

export default {
  baseURL,
  ownFetch
};
