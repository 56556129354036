import React, { Component } from "react";
import styled, { css } from "styled-components";
import { Helmet } from "react-helmet";
import DateTimePickerDefault from "react-datetime-picker";
import { Button } from "../modals/ConfirmationModal";
import Loader from "react-loader-spinner";
import { BsClockHistory, BsListCheck, FaCheck, ImBoxAdd } from "react-icons/all";
import EventSystem from "../../utils/EventSystem";
import { OrdersAPI } from "../../utils/api/OrdersAPI";
// import Select from "react-simple-select";
import { Order, OrderProductPrepQty, OrderState, OrderStatus } from "../../model/Order";
import Config from "../../utils/Config";
import { toast } from "react-toastify";
import { Input } from "../../pages/LoginPage";
import SelectOrg from "react-select";

const Select = styled(SelectOrg)`
  width: 25%;
  margin: 0 15px;
  @media screen and (max-width: 800px){
    margin: 5px 5px 0 5px;
    width: calc(65% - 10px);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 20px;
`;

const OrdersWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  //overflow-y: auto;
`;

const FiltersText = styled.h1`
  font-size: 16pt;
  width: 100%;
  text-align: left;
  margin: 0;
`;

const FiltersButtons = styled.div`
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`;

const FiltersWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: nowrap;
  padding: 5px 2%;
  
  @media screen and (max-width: 1000px){
    flex-wrap: wrap;
  }
`;

const DateWrapper = styled.div`
  margin: 0 5px;
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  & > span {
    margin: 0 0 3px 0;
  }

  @media screen and (max-width: 1000px) {
    width: fit-content;
  }
`;

const DateTimePicker = styled(DateTimePickerDefault)`
  width: 100%;
  margin-top: 0;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const OrderWrapper = styled.div`
  width: 98%;
  margin-top: 20px;
  margin-bottom: 5px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 0 10px 1px gray;
`;

const OrderDiv = styled.div`
  position:relative;
  width: 100%;
  min-height: 50px;
  border-radius: 15px;
  background-color: white;
  padding: 10px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:hover,
  &:active {
    cursor: pointer;
  }

  & > hr {
    display: block;
    width: 100%;
    height: 1px;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 2px 0;
  }

  i {
    font-size: 20pt;
  }
  
  border: 2px solid #ebebeb;

  ${({ status }) => status === OrderState.NEW && css`
    background-color: rgba(100,149,237,0.41);
    border: 2px solid cornflowerblue;
  `}
  
  ${({ status }) => status === OrderState.PACKAGING && css`
    background-color: rgba(19,132,150,0.54);
    border: 2px solid #138496;
  `}
  
  ${({ status, completed }) => status === OrderState.WAITING_FOR_CONFIRMATION && completed && css`
    background-color: rgba(128,0,128,0.34);
    border: 2px solid purple;
  `}
  
  ${({ status, completed }) => status === OrderState.WAITING_FOR_CONFIRMATION && !completed && css`
    background-color: rgba(128,0,55,0.34);
    border: 2px solid #800033;
  `}
  
  ${({ status }) => status === OrderState.CONFIRMING && css`
    background-color: rgba(255,127,80,0.49);
    border: 2px solid coral;
  `}
  
  ${({ status }) => status === OrderState.READY && css`
    background-color: rgba(200,35,51,0.44);
    border: 2px solid #c82333;
  `}
  
  ${({ status }) => status === OrderState.DONE && css`
    background-color: rgba(0,128,0,0.43);
    border: 2px solid #008000;
  `}
`;

const OrderComment = styled.span`
  width: 40%;
  @media screen and (max-width: 1000px){
    width: 100%;
  }
`;

const OrderID = styled.span`
  width: 15%;
  padding: 4px;
  border-radius: 20px;
  
  & > svg{
    margin-right: 5px;
  }
  
  @media screen and (max-width: 1000px){
    width: 50%;
  }
  
  &:hover, &:focus{
    cursor: pointer;
  }
`;

const OrderName = styled.span`
  width: 30%;
  @media screen and (max-width: 1000px){
    width: 50%;
  }
`;

// const OrderDate = styled.span`
//   width: 20%;
// `;

const OrderPhone = styled.span`
  width: 20%;
  @media screen and (max-width: 1000px){
    width: 50%;
    text-align: right;
  }
`;

const OrderStatusDiv = styled.span`
  width: 20%;
  text-align: right;
  font-weight: bold;
  @media screen and (max-width: 1000px){
    width: 50%;
  }
`;

const OrderShippingMethod = styled.span`
  width: 20%;
  @media screen and (max-width: 1000px){
    width: 50%;
    text-align: right;
  }
`;

const OrderPaymentMethod = styled.span`
  width: 20%;
  @media screen and (max-width: 1000px){
    width: 100%;
    text-align: right;
  }
`;

const OrderTotal = styled.span`
  width: 100%;
  cursor: pointer;
  
  @media screen and (max-width: 1000px){
    width: 100%;
    text-align: right;
  }
`;

const OrderAddress = styled.span`
  width: 50%;
`;

const OrderActionsWrapper = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
  padding: 5px;

  &:hover, &:active {
    cursor: initial;
  }

  & > div {
    margin: 0 20px;
    padding: 10px 5px;
    border-radius: 5px;
    min-width: 75px;

    display: flex;
    flex-direction: column;
    align-items: center;

    &:hover, &:active {
      cursor: pointer;
      background-color: whitesmoke;
    }

    & > svg {
      font-size: 30px;
    }

    & > span {
      font-size: 10pt;
    }
  }
  @media screen and (max-width: 1000px){
    width: 100%;
  }

`;

const OrderDetails = styled.div`
  width: 100%;
  min-height: 50px;
  border-radius: 0 0 15px 15px;
  background-color: #f5f5f5;
  padding: 10px;
  
  ${({ selected }) => !selected && css`
    display: none;
  `}
`;

const Product = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #aaaaaa;

  padding: 6px 5%;
  
  & > div:nth-child(1) {
    width: 13%;
    margin-right: 5px;
    flex-shrink: 0;
  }
  & > div:nth-child(2) {
    width: 55%;
    flex-grow: 2;
  }
  & > div:nth-child(3) {
    width: 15%;
    margin-left: 5px;
  }
  & > div:nth-child(4) {
    width: 15%;
    margin-left: 5px;
  }
  
  ${({ highlight }) => highlight !== undefined && css`
    background-color: ${highlight};
  `}
`;

// const Total = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: row;
//   flex-wrap: nowrap;
//   align-items: center;
//   justify-content: flex-end;
//   padding: 0 5%;
//
//   & > div {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-end;
//   }
// `;

const orderStateOptions: { value: number, label: string }[] = [
  { value: -1, label: "Minden állapot" },
  { value: OrderState.NEW, label: OrderState.getName(OrderState.NEW) },
  { value: OrderState.PACKAGING, label: OrderState.getName(OrderState.PACKAGING) },
  { value: OrderState.WAITING_FOR_CONFIRMATION, label: OrderState.getName(OrderState.WAITING_FOR_CONFIRMATION) },
  { value: OrderState.CONFIRMING, label: OrderState.getName(OrderState.CONFIRMING) },
  { value: OrderState.READY, label: OrderState.getName(OrderState.READY) },
  { value: OrderState.DONE, label: OrderState.getName(OrderState.DONE) }
];

export default class Orders extends Component {
  state: {
    orders: Order[],
    orderProductPrepQtyList: OrderProductPrepQty[],
    editMinDate: Date,
    editMaxDate: Date,
    loading: boolean,
    selectedOrder: number,
    orderStatuses: OrderStatus[],
    showModal: boolean,
    mobile: boolean,
    filterOrderNumber: string,
    filteredState: { value: number, label: string }[],
  } = {
    editMinDate: this.getMonday(new Date()),
    editMaxDate: new Date(),
    loading: false,
    selectedOrder: -1,
    orderStatuses: [],
    orders: [],
    showModal: false,
    filterOrderNumber: "",
    filteredState: [orderStateOptions[0]],
    selectedFilteredStatusOptions: { value: 1, label: "Új rendelés" },
    mobile: window.innerWidth < 1000,
    orderProductPrepQtyList: []
  };

  handleResize() {
    this.setState({ mobile: window.innerWidth < Config.mobileMaxWidth });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => this.handleResize());
  }

  componentDidMount() {
    this.loadOrders(this.state.editMinDate, this.state.editMaxDate);
    window.addEventListener("resize", () => this.handleResize());

    // noinspection JSUnusedLocalSymbols
    EventSystem.subscribe(EventSystem.events.orderStatusAdded, ({ orderId, newStatus, orderProductPrepQtyList }) => {
      let orderStatuses: OrderStatus[] = [];
      for (let orderState of this.state.orderStatuses)
        orderStatuses.push({ ...orderState });
      orderStatuses.push(newStatus);

      this.setState({ orderStatuses });

      if (orderProductPrepQtyList !== undefined && orderProductPrepQtyList.length > 0) {
        let newOrderProductPrepQtyList: OrderProductPrepQty[] = [];
        for (let orderProductPrepQty of this.state.orderProductPrepQtyList) {
          if (orderProductPrepQty.orderID !== orderId)
            newOrderProductPrepQtyList.push(orderProductPrepQty);
        }

        for (let op: { sku: string, qty: number } of orderProductPrepQtyList) {
          newOrderProductPrepQtyList.push(new OrderProductPrepQty(orderId, op.sku, op.qty));
        }
        this.setState({ orderProductPrepQtyList: newOrderProductPrepQtyList });
      }
    });
  }

  render() {
    return (
      <Wrapper>
        <Helmet>
          <title>Gold Dekor Csomagolás - Rendelések</title>
          <meta name="description" content="Gold Dekor Felhasználók"/>
        </Helmet>

        <FiltersText>Szűrők</FiltersText>
        <FiltersWrapper>
          <DateWrapper>
            <span>Kezdő dátum: </span>
            <DateTimePicker
              onChange={(date) => {
                this.setState({ editMinDate: date });
              }}
              value={this.state.editMinDate}
              locale={"HU"}
              disableClock
              format={"yyyy.MM.dd."}
            />
          </DateWrapper>
          <DateWrapper>
            <span>Végső dátum: </span>
            <DateTimePicker
              onChange={(date) => {
                this.setState({ editMaxDate: date });
              }}
              value={this.state.editMaxDate}
              locale={"HU"}
              disableClock
              format={"yyyy.MM.dd."}
            />
          </DateWrapper>
          <FiltersButtons>
            <Button onClick={() => this.loadOrders(this.state.editMinDate, this.state.editMaxDate)}>
              Lekérés
            </Button>
            {/*<Button onClick={() => this.export()}>Export</Button>*/}
          </FiltersButtons>
        </FiltersWrapper>
        <FiltersWrapper>
          <Input
            id={"ordernumber"}
            value={this.state.filterOrderNumber}
            type="text"
            autoComplete={"ordernumber"}
            placeholder="#Rendelés"
            onChange={(e) => this.setState({ filterOrderNumber: e.target.value })}
            width={"25%"}
            margin={"0"}
            padding={"1px 1px 3px 1px"}
            mwidth={"34%"}
          />
          <Select
            placeholder={"Állapot (mind)"}
            value={this.state.filteredState}
            onChange={e => this.setState({ filteredState: e })}
            options={orderStateOptions}
            noOptionsMessage={() => "Nincs kiválasztható állapot"}
            isMulti={true}
          />
        </FiltersWrapper>
        <OrdersWrapper>
          {this.state.orders.length > 0 && this.state.orders.map((order: Order, i: number) => {
              let state = Orders.findLastState(order.id, this.state.orderStatuses);
              if (!state)
                state = {};
              let orderProducts = order.orderProducts;

              if (this.state.filterOrderNumber.length > 0 && !(order.innerId + "").includes(this.state.filterOrderNumber)) {
                return <React.Fragment key={i}/>;
              }

              if (
                !this.state.filteredState.find(fs => fs.value === -1) &&
                !this.state.filteredState.find(fs => fs.value === state.orderState.value)
              ) {
                return <React.Fragment key={i}/>;
              }

              let orderProductPrepQtyList: OrderProductPrepQty[] = this.state.orderProductPrepQtyList.filter(v => v.orderID === order.id);
              let completedPackaging: boolean = true;
              for (let orderProduct of order.orderProducts) {
                let op: OrderProductPrepQty = orderProductPrepQtyList.find(v => v.orderProductSKU === orderProduct.sku);
                if (!op || op.qty < orderProduct.stock1) {
                  completedPackaging = false;
                  break;
                }
              }

              let stateName = OrderState.getName(state.orderState.value);
              if (state.orderState.value === OrderState.WAITING_FOR_CONFIRMATION && !completedPackaging) {
                stateName += ", hiányos";
              }

              return (
                <OrderWrapper key={i}>
                  <OrderDiv completed={completedPackaging} status={state.orderState.value}
                            selected={this.state.selectedOrder === order.id}
                            onClick={() => this.selectOrder(order.id)}
                  >
                    <OrderID onClick={(e) => this.showHistory(e, order)}>
                      <BsClockHistory/>
                      <b>{order.innerId}</b>
                      {/*<b>{order.date.toHunFormatMinutes()}</b>*/}
                    </OrderID>
                    {this.state.mobile &&
                    <OrderStatusDiv>{stateName}</OrderStatusDiv>
                    }
                    <OrderName>{order.firstname + " " + order.lastname} ({order.customerGroup.name})</OrderName>
                    <OrderPhone onClick={(e) => e.stopPropagation()}>
                      <a href={"tel:" + order.phone}>
                        <i className="fas fa-phone"/>
                        {order.phone}
                      </a>
                    </OrderPhone>
                    {!this.state.mobile &&
                    <OrderStatusDiv>{stateName}</OrderStatusDiv>
                    }

                    <hr/>

                    <OrderAddress>
                      {order.shippingPostcode} {order.shippingCity}, {order.shippingAddress1}
                    </OrderAddress>
                    <OrderShippingMethod>{order.shippingMethodName}</OrderShippingMethod>
                    <OrderPaymentMethod>{order.paymentMethodName}</OrderPaymentMethod>
                    <OrderTotal onClick={(e) => this.handleOrderTotalClick(e, order)}>
                      <b>Végösszeg:</b>{" " + parseInt(order.total)} Ft
                    </OrderTotal>
                    <hr/>

                    <OrderComment>
                      {order.comment &&
                      <>
                        <b>Megjegyzés:</b>
                        <br/>
                        {order.comment}
                      </>
                      }
                    </OrderComment>
                    {state.orderState.value !== OrderState.DONE &&
                    <OrderActionsWrapper onClick={(e) => e.stopPropagation()}>
                      <span>Műveletek:</span>
                      {(state.orderState.value === OrderState.NEW || state.orderState.value === OrderState.PACKAGING) &&
                      <div onClick={() => this.startOrderPreparation(order)}>
                        <ImBoxAdd/>
                        <span>Összeszedés</span>
                      </div>
                      }
                      {state.orderState.value === OrderState.WAITING_FOR_CONFIRMATION && !completedPackaging &&
                      <div onClick={() => this.startOrderPreparation(order)}>
                        <ImBoxAdd/>
                        <span>Összeszedés folytatása</span>
                      </div>
                      }
                      {(state.orderState.value === OrderState.WAITING_FOR_CONFIRMATION || state.orderState.value === OrderState.CONFIRMING) &&
                      <div onClick={() => this.startOrderCheck(order)}>
                        <BsListCheck/>
                        <span>Csomagolás</span>
                      </div>
                      }
                      <div onClick={() => this.hardFinishOrder(order)}>
                        <FaCheck/>
                        <span>Futárnak átadva</span>
                      </div>
                      {/*{(state.orderState.value === OrderState.READY) &&*/}
                      {/*<div onClick={() => this.sendInvoice(order)}>*/}
                      {/*  <FaCheck/>*/}
                      {/*  <span>Számla küldése</span>*/}
                      {/*</div>*/}
                      {/*}*/}
                    </OrderActionsWrapper>
                    }
                  </OrderDiv>
                  {this.state.selectedOrder === order.id &&
                  <OrderDetails selected={this.state.selectedOrder === order.id}>
                    {orderProducts.map((orderProduct, i) => {
                      return (
                        <Product key={i}>
                          <div>{orderProduct.stock1}db</div>
                          <div>{orderProduct.name}</div>
                          <div>{Math.round(orderProduct.price)} Ft</div>
                          <div>{Math.round(orderProduct.total)} Ft</div>
                        </Product>
                      );
                    })}
                    {/*<Total>*/}
                    {/*  <div>*/}
                    {/*    <span>Összesen: {order.orderTotalPrice}Ft</span>*/}
                    {/*  </div>*/}
                    {/*</Total>*/}
                  </OrderDetails>
                  }
                </OrderWrapper>
              );
            }
          )}
          {this.state.loading && (
            <LoaderWrapper>
              <Loader visible={this.state.loading} type="ThreeDots" color="rgb(106,162,5)" height={20} width={100}/>
            </LoaderWrapper>
          )}
        </OrdersWrapper>
      </Wrapper>
    );
  }

  // noinspection JSUnusedGlobalSymbols
  searchOrders() {
    this.setState({ loading: true });
    // OrdersAPI.getOrders(true, this.state.editMinDate, this.state.editMaxDate, (res) => {
    //   let orders = res.orders;
    //   this.finisehdLoaded = true;
    //   this.setState({
    //     finishedOrders: orders.sort((o1, o2) => o2.id - o1.id),
    //     finishedProducts: res.products,
    //   });
    //   ContextSystem.mergeCities(res.cities, res.zipCodes);
    //   this.setState({ loading: false });
    // });
  }

  getMonday(d) {
    d = new Date(d);
    let day = d.getDay();
    let diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    // diff = new Date(2021, 1, 2);
    // return diff;
    return new Date(d.setDate(diff));
  }

  selectOrder(id) {
    if (this.state.selectedOrder === id)
      this.setState({ selectedOrder: -1 });
    else
      this.setState({ selectedOrder: id });
  }

  startOrderPreparation(order: Order) {
    let orderStatuses: Array<OrderStatus> = [];
    for (let orderStatus: OrderStatus of this.state.orderStatuses) {
      if (orderStatus.orderID === order.id)
        orderStatuses.push(orderStatus);
    }
    let orderProductPrepQtyList: OrderProductPrepQty[] = this.state.orderProductPrepQtyList.filter(v => v.orderID === order.id);
    EventSystem.publish(EventSystem.events.showOrderDetails, {
      order,
      orderStatuses,
      preparation: true,
      control: false,
      orderProductPrepQtyList
    });
  }

  hardFinishOrder(order){
    OrdersAPI.changeOrderStatus(order.id, OrderState.READY, undefined, res => {
      let resTyped: {
        error: number,
        type: number,
        orderStatus: OrderStatus,
      } = res;

      if (resTyped.error === 0) {
        let orderStatus = res.orderStatus;
        orderStatus.date = new Date(orderStatus.date.value);
        EventSystem.publish(EventSystem.events.orderStatusAdded, {
          orderId: order.id,
          newStatus: orderStatus
        });
      }
    });
  }

  startOrderCheck(order) {
    let orderStatuses: Array<OrderStatus> = [];
    for (let orderStatus: OrderStatus of this.state.orderStatuses) {
      if (orderStatus.orderID === order.id)
        orderStatuses.push(orderStatus);
    }
    EventSystem.publish(EventSystem.events.showOrderDetails, {
      order,
      orderStatuses,
      preparation: false,
      control: true
    });
  }

  loadOrders(editMinDate: Date, editMaxDate: Date) {
    this.setState({ loading: true });
    editMaxDate = editMaxDate.addDays(1);
    OrdersAPI.getOrders(editMinDate, editMaxDate, res => {
      if (res.error === 0) {
        let orderStatuses = res.orderStatuses;
        let orders = res.orders;
        let orderProductPrepQtyList = res.orderProductPrepQtyList;

        orders.forEach(o => o.date = new Date(o.dateCreated.value));
        orderStatuses.forEach(o => o.date = new Date(o.date.value));

        orderStatuses = orderStatuses.sort((os1, os2) => {
          if (os1.date === os2.date)
            return 0;
          else if (os1.date < os2.date)
            return 1;
          else
            return -1;
        });
        orders = orders.sort((o1: Order, o2: Order) => {
          if (o1.date === o2.date)
            return 0;
          else if (o1.date < o2.date)
            return 1;
          else
            return -1;
        });
        // orders = orders.sort((o1, o2) => o2.date - o1.date);

        this.setState({ loading: false, orders, orderStatuses, orderProductPrepQtyList });
      } else {
        this.setState({ loading: false });
      }
    });
  }

  // export() {}

  static findLastState(orderId, orderStatuses: Array<OrderStatus>): OrderStatus {
    let bestOS: OrderStatus = undefined;
    for (let os of orderStatuses) {
      if (os.orderID === orderId) {
        if (!bestOS)
          bestOS = os;
        else {
          if (os.date > bestOS.date)
            bestOS = os;
        }
      }
    }
    if (!bestOS)
      bestOS = {
        orderID: orderId,
        profileID: -1,
        orderState: { value: 1, name: "Új rendelés" },
        date: null,
        comment: "",
        automatic: true
      };

    return bestOS;
  }

  // noinspection JSUnusedGlobalSymbols
  sendInvoice(order) {
    let currentStatus: OrderStatus = Orders.findLastState(order.id, this.state.orderStatuses);
    if (currentStatus.orderState.value !== OrderState.READY) {
      toast("A rendelés állapota nem engedi meg, hogy számlát generáljunk!");
      return;
    }

    let newState: number = OrderState.getNextState(currentStatus.orderState.value);
    OrdersAPI.changeOrderStatus(order.id, newState, undefined, res => {
      let resTyped: {
        error: number,
        type: number,
        orderStatus: OrderStatus,
      } = res;

      if (resTyped.error === 0) {
        let orderStatus = res.orderStatus;
        orderStatus.date = new Date(orderStatus.date.value);
        EventSystem.publish(EventSystem.events.orderStatusAdded, {
          orderId: order.id,
          newStatus: orderStatus
        });
      }
    });
    return undefined;
  }

  showHistory(e: Event, order: Order) {
    e.stopPropagation();

    let orderStatuses: Array<OrderStatus> = [];
    for (let os of this.state.orderStatuses) {
      if (os.orderID === order.id)
        orderStatuses.push(os);
    }
    orderStatuses.push({
      orderID: order.id,
      profileID: -1,
      orderState: { value: 1, name: "NEW" },
      date: order.date,
      comment: "",
      automatic: true
    });

    orderStatuses = orderStatuses.sort((os1, os2) => {
      if (os1.date === os2.date)
        return 0;
      else if (os1.date < os2.date)
        return 1;
      else
        return -1;
    });

    let render = (
      <div>
        <p>
          <b>Rendelésszám: </b>{order.innerId}<br/>
          <b>Rendelés leadva: </b>{order.date.toHunFormatMinutes()}<br/>
          <b>Rendelő neve: </b>{order.firstname} {order.lastname}<br/>
        </p>
        <hr/>
        <ul>
          {orderStatuses.map(os => {
            let comment = os.comment ? " - " + os.comment : "";
            return (
              <li>
                {os.date.toHunFormatMinutes()} {comment} - <b>{OrderState.getName(os.orderState.value)}</b>
              </li>
            );
          })}
        </ul>
      </div>
    );

    EventSystem.publish(EventSystem.events.show_confirmation_modal, {
      title: "Rendelés történet",
      text: render,
      yesText: "Ok",
      noText: ""
    });
  }

  handleOrderTotalClick(e: Event, order: Order) {
    e.stopPropagation();

    let orderProductPrepQtyList: OrderProductPrepQty[] = this.state.orderProductPrepQtyList.filter(v => v.orderID === order.id);

    let render = (
      <div>
        <p>
          <b>Rendelésszám: </b>{order.innerId}<br/>
          <b>Rendelés leadva: </b>{order.date.toHunFormatMinutes()}<br/>
          <b>Rendelő neve: </b>{order.firstname} {order.lastname}<br/>
        </p>
        <hr/>
        <div>
          <Product>
            <div><b>Rendelt mennyiség</b></div>
            <div><b>Terméknév</b></div>
            <div><b>Csomagolt mennyiség</b></div>
          </Product>
          {order.orderProducts.map((op, i) => {
              let qty = orderProductPrepQtyList.find(v => v.orderProductSKU === op.sku)?.qty ?? 0;
              let color = "rgba(2,149,2,0.66)";
              if (qty <= 0)
                color = "rgba(187,22,22,0.71)";
              else if (qty > 0 && qty < op.stock1)
                color = "rgba(255,165,0,0.66)";
              return (
                <Product key={i} highlight={color}>
                  <div>{op.stock1}db</div>
                  <div>{op.name}</div>
                  <div>{qty}db</div>
                </Product>);
            }
          )}
        </div>
      </div>
    );

    EventSystem.publish(EventSystem.events.show_confirmation_modal, {
      title: "Hiányzó termékek",
      text: render,
      yesText: "Ok",
      noText: ""
    });
  }
}
