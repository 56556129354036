import APICaller from "../APICaller";
import { Shelf } from "../../model/Shelf";
import { Product } from "../../model/Product";

function getShelves(cb: (result: { error: number, type: number, shelves: Array<Shelf> })=>{}) {
  APICaller.ownFetch(false, "POST", "/shelves", {}, (res) => {
    let result = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function saveShelves(shelves: Array<Shelf>, cb: (result: { error: number, type: number, shelves: Array<Shelf> })=>{}) {
  APICaller.ownFetch(false, "PUT", "/shelves", { shelves }, (res) => {
    let result = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function getProducts(cb: (result: { error: number, type: number, products: Array<Product> })=>{}) {
  APICaller.ownFetch(true, "POST", "/products", {}, (res) => {
    let result = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

export const ShelvesAPI = {
  saveShelves,
  getShelves,
  getProducts
};
