import React, { Component } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import GridLayoutOrg from "react-grid-layout";
import { BsTrashFill, FaPlus, IoSettingsSharp } from "react-icons/all";
import { toast } from "react-toastify";
import Config from "../../utils/Config";
import { Button } from "../modals/ConfirmationModal";
import { Shelf } from "../../model/Shelf";
import { ShelvesAPI } from "../../utils/api/ShelvesAPI";
import Loader from "react-loader-spinner";
import { LoaderWrapper } from "./Orders";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";

const GridWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 120px;
`;

const GridLayout = styled(GridLayoutOrg)`
  width: 80%;
`;

export const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;

  & > h2 {
    font-size: 20pt;
  }
`;


const Wrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
`;

const ShelfDiv = styled.div`
  cursor: grab;
  
  user-select: none;
  background-color: rgb(117,175,5);
  border-radius: 10px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ShelfItemNumbers = styled.div`
  width: 100%;
  text-align: center;
  margin: 3px 0;
  font-size: 10pt;
  font-weight: bold;
`;

const ShelfActions = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 4px;
  
  & > svg{
    padding: 3px;
    font-size: 16pt;
    width: fit-content;
    height: fit-content;
    &:hover, &:focus{
      cursor: pointer;
    }
  }
`;

const EmptyShelf = styled.div`
  cursor: grab;

  background-color: white;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 5px 1px #cdcdcd;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  font-size: 20px;
  color: rgb(33,33,33);
  
  svg{
    &:hover, &:focus{
      cursor: pointer;
      transform: scale(1.2);
    }
    width: fit-content;
    height: fit-content;
    padding: 10px;
    transition: transform 200ms ease-in-out;
  }
`;

class ShelfLayout {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;


  constructor(i: string, x: number, y: number, w: number, h: number) {
    this.i = i;
    this.x = x;
    this.y = y;
    this.w = w;
    this.h = h;
  }
}

export default class Shelves extends Component {
  state: {
    shelvesLayout: Array<ShelfLayout>,
    shelves: Array<Shelf>,
    mobile: boolean,
    loading: boolean,
  } = {
    shelvesLayout: [],
    shelves: [],
    mobile: window.innerWidth < 1000,
    loading: false
  };

  // noinspection DuplicatedCode
  static getRowCount(shelvesLayout: Array<ShelfLayout>) {
    if (shelvesLayout.length <= 0)
      return 0;

    let maxY = shelvesLayout[0].y;
    if (shelvesLayout[0].h > 1)
      maxY += shelvesLayout[0].h - 1;
    for (let sl of shelvesLayout) {
      if (sl.h > 1) {
        if (sl.y + sl.h > maxY)
          maxY = sl.y + sl.h;
      } else {
        if (sl.y > maxY)
          maxY = sl.y;
      }
    }
    return maxY + 1;
  }

  static getMaxID(shelvesLayout: Array<ShelfLayout>) {
    if (shelvesLayout.length <= 0)
      return 0;
    let maxID = parseInt(shelvesLayout[0].i);
    for (let shelvesLayoutElement of shelvesLayout) {
      if (parseInt(shelvesLayoutElement.i) > maxID)
        maxID = parseInt(shelvesLayoutElement.i);
    }
    return maxID;
  }

  // noinspection DuplicatedCode
  static getColCount(shelvesLayout: Array<ShelfLayout>) {
    if (shelvesLayout.length <= 0)
      return 0;
    let maxX = shelvesLayout[0].x;
    if (shelvesLayout[0].w > 1)
      maxX += shelvesLayout[0].w - 1;
    for (let sl of shelvesLayout) {
      if (sl.w > 1) {
        if (sl.x + sl.w > maxX)
          maxX = sl.x + sl.w;
      } else {
        if (sl.x > maxX)
          maxX = sl.x;
      }
    }
    return maxX + 1;
  }

  // noinspection DuplicatedCode
  addRow() {
    let rows = Shelves.getRowCount(this.state.shelvesLayout);
    let cols = Shelves.getColCount(this.state.shelvesLayout);
    let shelvesLayout = [];
    let shelves = [];
    for (let shelvesLayoutElement of this.state.shelvesLayout)
      shelvesLayout.push(shelvesLayoutElement);
    for (let shelf of this.state.shelves)
      shelves.push(shelf);

    let maxID = Shelves.getMaxID(shelvesLayout);
    maxID++;
    if (cols <= 0)
      cols++;
    for (let x = 0; x < cols; x++) {
      let i = "" + maxID;
      shelvesLayout.push({ i, x, y: rows, w: 1, h: 1 });
      shelves.push({ id: maxID, empty: true });
      maxID++;
    }

    this.setState({ shelvesLayout, shelves });
  }

  // noinspection DuplicatedCode
  addCol() {
    let rows = Shelves.getRowCount(this.state.shelvesLayout);
    let cols = Shelves.getColCount(this.state.shelvesLayout);
    let shelvesLayout = [];
    let shelves = [];
    for (let shelvesLayoutElement of this.state.shelvesLayout)
      shelvesLayout.push(shelvesLayoutElement);
    for (let shelf of this.state.shelves)
      shelves.push(shelf);

    let maxID = Shelves.getMaxID(shelvesLayout);
    maxID++;
    if (rows <= 0)
      rows++;

    for (let y = 0; y < rows; y++) {
      let i = "" + maxID;
      shelvesLayout.push({ i, x: cols, y, w: 1, h: 1 });
      shelves.push(new Shelf(cols, y, 1, 1, maxID, true, true));
      maxID++;
    }

    let expansion = this.expandLayoutWithEmptyCells(shelvesLayout, shelves);

    this.setState({
      shelvesLayout: expansion.shelvesLayout,
      shelves: expansion.shelves
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => this.handleResize());
  }

  componentDidMount() {
    window.addEventListener("resize", () => this.handleResize());
    this.loadShelves();

    EventSystem.subscribe(EventSystem.events.productsSelected, ({ shelfID, productsSelected }) => {
      let shelves: Array<Shelf> = [];
      for (let shelf: Shelf of this.state.shelves) {
        if (shelf.id === shelfID) {
          let itemIDsAndSKUs: Array<{ first: string, second: string }> = [];
          for (let productId of productsSelected) {
            for (let product of ContextSystem.products) {
              if (product.id === productId) {
                itemIDsAndSKUs.push({ key: product.id, value: product.sku });
                break;
              }
            }
          }
          shelf.itemIDsandSKus = itemIDsAndSKUs;
        }
        shelves.push(shelf);
      }
      this.setState({ shelves });
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ shelves }) => {
      if (shelves !== undefined) {
        this.loadShelves();
      }
    });
  }

  handleResize() {
    this.setState({ mobile: window.innerWidth < Config.mobileMaxWidth });
  }

  render() {
    return (
      <Wrapper>
        <Helmet>
          <title>Gold Dekor Csomagolás - Polcok</title>
          <meta name="description" content="Gold Dekor Polcok"/>
        </Helmet>
        <TopWrapper>
          <h2>Polcok</h2>
        </TopWrapper>
        {this.state.mobile &&
        <div>
          Ez az oldal csak számítógépen elérhető!
        </div>
        }
        {this.state.mobile === false &&
        <>
          <div>
            <Button onClick={() => this.addRow()}>
              Sor hozzáadása
            </Button>
            <Button onClick={() => this.addCol()}>
              Oszlop hozzáadása
            </Button>
            <Button onClick={() => this.handleSaveClicked()}>
              Mentés
            </Button>
          </div>

          {this.state.loading && (
            <LoaderWrapper>
              <Loader visible={this.state.loading} type="ThreeDots" color="rgb(106,162,5)" height={20} width={100}/>
            </LoaderWrapper>
          )}
          {!this.state.loading && (
            <GridWrapper>
              <GridLayout className="layout" layout={this.state.shelvesLayout}
                          cols={Shelves.getColCount(this.state.shelvesLayout)} rowHeight={100}
                          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                          margin={[10, 10]}
                          width={Shelves.getColCount(this.state.shelvesLayout) * 110 + 10}
                          compactType={"vertical"}
                          onLayoutChange={shelvesLayout => {
                            let exRes = this.expandLayoutWithEmptyCells(shelvesLayout, this.state.shelves);
                            this.setState({
                              shelvesLayout: exRes.shelvesLayout,
                              shelves: exRes.shelves
                            });
                          }}
              >
                {this.state.shelvesLayout.map((s) => {
                  let shelf = this.getShelf(parseInt(s.i));
                  if (shelf.empty) {
                    return (
                      <EmptyShelf key={s.i}>
                        <FaPlus
                          onClick={() => this.fillShelf(parseInt(s.i))}
                          onMouseDown={e => e.stopPropagation()}
                          onMouseUp={e => e.stopPropagation()}
                        />
                      </EmptyShelf>
                    );
                  } else {
                    return (
                      <ShelfDiv key={s.i} onDoubleClick={() => this.removeCell(s.i)}>
                        <ShelfItemNumbers>
                          {shelf.itemIDsandSKus.length} termék
                        </ShelfItemNumbers>
                        <ShelfActions>
                          <IoSettingsSharp
                            onClick={(e: MouseEvent) => {
                              e.stopPropagation();
                              this.handleEditCellProductsClicked(s.i);
                            }}
                            onMouseDown={(e: MouseEvent) => e.stopPropagation()}
                            onMouseUp={(e: MouseEvent) => e.stopPropagation()}
                          />
                          <BsTrashFill
                            onClick={(e: MouseEvent) => {
                              e.stopPropagation();
                              this.removeCell(s.i);
                            }}
                            onMouseDown={(e: MouseEvent) => e.stopPropagation()}
                            onMouseUp={(e: MouseEvent) => e.stopPropagation()}
                          />
                        </ShelfActions>
                      </ShelfDiv>
                    );
                  }
                })}
              </GridLayout>
            </GridWrapper>
          )}
        </>
        }
      </Wrapper>
    );
  }

  removeCell(id: string) {
    let filledShelvesCount = 0;
    for (let shelf of this.state.shelves) {
      if (shelf.empty !== true)
        filledShelvesCount++;
    }
    if (filledShelvesCount <= 1) {
      toast("Egy polcnak kell lennie legalább.");
      return;
    }

    let shelf = this.getShelf(parseInt(id));
    if (shelf.itemIDsandSKus.length > 0) {
      toast("Csak üres polc törölhető!");
      return;
    }

    let newID = Shelves.getMaxID(this.state.shelvesLayout) + 1;

    let shelvesLayout = [];
    for (let sl of this.state.shelvesLayout) {
      let sl2 = { ...sl };
      if (sl.i === id) {
        sl2.i = "" + newID;
      }
      shelvesLayout.push(sl2);
    }

    let shelves: Array<Shelf> = [];
    for (let s of this.state.shelves) {
      let s2 = { ...s };
      if (s.id === parseInt(id)) {
        s2 = new Shelf(s.x, s.y, s.w, s.h, newID, true, true);
      }
      shelves.push(s2);
    }
    this.setState({ shelvesLayout, shelves });
  }

  lastExpandedLayoutResult: Array<ShelfLayout> = null;
  lastExpandedShelves: Array<Shelf> = null;

  // noinspection DuplicatedCode
  expandLayoutWithEmptyCells(shelvesLayoutOrg: Array<ShelfLayout>, shelvesOrg: Array<Shelves>) {
    if (this.arraysEqual(shelvesLayoutOrg, this.lastExpandedLayoutResult)
      && this.arraysEqual(shelvesOrg, this.lastExpandedShelves)
    ) {
      return {
        shelvesLayout: shelvesLayoutOrg,
        shelves: shelvesOrg
      };
    }

    let usedCells = [];
    let rowCount = 0;
    let colCount = 0;
    for (let s of shelvesLayoutOrg) {
      usedCells.push({ x: s.x, y: s.y });
      if (s.x + 1 > colCount)
        colCount = s.x + 1;
      if (s.y + 1 > rowCount)
        rowCount = s.y + 1;
      if (s.w > 1) {
        if (s.x + s.w > colCount)
          colCount = s.x + +s.w;
        for (let offset = 1; offset < s.w; offset++)
          usedCells.push({ x: s.x + offset, y: s.y });
      }
      if (s.h > 1) {
        if (s.y + s.h > rowCount)
          rowCount = s.y + s.h;
        for (let offset = 1; offset < s.h; offset++)
          usedCells.push({ x: s.x, y: s.y + offset });
      }
      if (s.h > 1 && s.w > 1) {
        for (let offsetY = 1; offsetY < s.h; offsetY++) {
          for (let offsetX = 1; offsetX < s.w; offsetX++) {
            if (offsetX === offsetY === 1)
              continue;
            usedCells.push({ x: s.x + offsetX, y: s.y + offsetY });
          }
        }
      }
    }

    let unusedCells = [];
    for (let x = 0; x < colCount; x++) {
      for (let y = 0; y < rowCount; y++) {
        if (usedCells.filter(v => v.x === x && v.y === y).length <= 0) {
          unusedCells.push({ x, y });
        }
      }
    }

    let shelvesLayout: Array<ShelfLayout> = [];
    let shelves = [];
    for (let shelvesLayoutElement of shelvesLayoutOrg)
      shelvesLayout.push(shelvesLayoutElement);
    for (let s of shelvesOrg)
      shelves.push(s);

    let maxID = Shelves.getMaxID(shelvesLayout);
    maxID++;
    for (let unusedCell of unusedCells) {
      shelvesLayout.push(new ShelfLayout("" + maxID, unusedCell.x, unusedCell.y, 1, 1));
      shelves.push({ id: maxID, empty: true });
      maxID++;
    }

    rowCount = Shelves.getRowCount(shelvesLayout);
    colCount = Shelves.getColCount(shelvesLayout);

    let lastTwoRowsEmpty = true, lasTwoColumnsEmpty = true;
    let firstTwoRowsEmpty = true, firstTwoColumnsEmpty = true;
    for (let sl of shelvesLayout) {
      let shelf = this.getShelf(parseInt(sl.i));
      if (!shelf)
        continue;
      if (
        (sl.x === colCount - 1 && shelf.empty !== true) ||
        (sl.x === colCount - 2 && shelf.empty !== true)
      ) {
        lasTwoColumnsEmpty = false;
      }
      if (
        (sl.x === 0 && shelf.empty !== true) ||
        (sl.x === 1 && shelf.empty !== true)
      ) {
        firstTwoColumnsEmpty = false;
      }
      if (
        (sl.y === rowCount - 1 && shelf.empty !== true) ||
        (sl.y === rowCount - 2 && shelf.empty !== true)
      ) {
        lastTwoRowsEmpty = false;
      }
      if (
        (sl.y === 0 && shelf.empty !== true) ||
        (sl.y === 1 && shelf.empty !== true)
      ) {
        firstTwoRowsEmpty = false;
      }
      if (!lasTwoColumnsEmpty && !lastTwoRowsEmpty && !firstTwoRowsEmpty && !firstTwoColumnsEmpty)
        break;
    }

    if (lastTwoRowsEmpty)
      shelvesLayout = shelvesLayout.filter(v => v.y !== rowCount - 1);
    if (firstTwoRowsEmpty) {
      shelvesLayout = shelvesLayout.filter(v => v.y !== 0);
      shelvesLayout.forEach(sl => sl.y--);
    }
    if (lasTwoColumnsEmpty)
      shelvesLayout = shelvesLayout.filter(v => v.x !== colCount - 1);
    if (firstTwoColumnsEmpty) {
      shelvesLayout = shelvesLayout.filter(v => v.x !== 0);
      shelvesLayout.forEach(sl => sl.x--);
    }
    shelves.filter(v => {
      let found = false;
      for (let sl of shelvesLayout) {
        if (parseInt(sl.i) === v.id) {
          found = true;
          break;
        }
      }
      return found;
    });

    this.lastExpandedLayoutResult = shelvesLayout;
    this.lastExpandedShelves = shelves;
    return {
      shelvesLayout,
      shelves
    };
  }

  arraysEqual(a: Array<any>, b: Array<any>) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  fillShelf(id: number) {
    let shelves = [];
    for (let shelf of this.state.shelves) {
      if (shelf.id === id)
        shelf.empty = false;
      shelves.push(shelf);
    }
    this.setState({ shelves });
  }

  getShelf(id: number) {
    let shelvesFiltered = this.state.shelves.filter((s) => s.id === id);
    if (shelvesFiltered.length > 0)
      return shelvesFiltered[0];
    return null;
  }

  handleSaveClicked() {
    let shelves: Array<Shelf> = [];
    for (let shelf of this.state.shelves) {
      for (let e of this.state.shelvesLayout) {
        if (shelf.id === parseInt(e.i)) {
          shelf.x = e.x;
          shelf.y = e.y;
          shelf.width = e.w;
          shelf.height = e.h;
          shelf.enabled = true;
          shelves.push(shelf);
          break;
        }
      }
    }
    ShelvesAPI.saveShelves(shelves, (result) => {
      if (result.error === 0) {
        toast("Mentve!");
      }
    });
  }

  loadShelves() {
    this.setState({ loading: true });

    if (!ContextSystem.shelves || ContextSystem.shelves.length <= 0)
      return;

    let shelvesLayout: Array<ShelfLayout> = [];

    let shelves: Array<Shelf> = [];

    for (let shelf of ContextSystem.shelves) {
      shelves.push({ ...shelf });
    }

    if (!shelves || shelves.length <= 0)
      return;
    for (let shelf of shelves) {
      shelvesLayout.push(new ShelfLayout(shelf.id + "", shelf.x, shelf.y, shelf.width, shelf.height));
    }

    this.setState({ loading: false, shelvesLayout, shelves });
  }


  handleEditCellProductsClicked(id: string) {
    let shelf = this.getShelf(parseInt(id));
    let selectedProductIDs: Array<string> = [];
    for (let itemIDsandSKus of shelf.itemIDsandSKus) {
      selectedProductIDs.push(itemIDsandSKus.key);
    }
    EventSystem.publish(EventSystem.events.openProductsSelector, { shelfID: parseInt(id), selectedProductIDs });
  }
}
