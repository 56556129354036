class EventSystem {
  static queue = {};
  static idCounter = 0;

  static events = {
    authentication_changed: 1,
    urlChanged: 2,
    sideMenu: 3,
    contextSystemChanged: 4,
    show_confirmation_modal: 5,
    showOrderDetails: 6,
    orderStatusAdded: 7,
    openProductsSelector: 8,
    productsSelected: 9,
    playSound: 10,
  };

  static publish(event, data) {
    // let a:E = this.events2.authentication_changed;
    // a.
    if (typeof EventSystem.queue === "undefined") {
      return false;
    }
    if (Object.keys(EventSystem.queue).length > 0) {
      if (typeof EventSystem.queue[event] !== "undefined" && EventSystem.queue[event].length > 0)
        for (let a of EventSystem.queue[event]) a.callback(data);
    }
  }

  static unsubscribe(id) {
    let eventName;
    let index = -1;
    A: for (let event in EventSystem.queue) {
      let eventSubscribers = EventSystem.queue[event];
      for (let i in eventSubscribers) {
        // noinspection JSUnfilteredForInLoop
        if (eventSubscribers[i].id === id) {
          index = i;
          eventName = event;
          break A;
        }
      }
    }
    if (index >= 0) {
      EventSystem.queue[eventName].splice(index, 1);
    }
  }

  static subscribe(event, callback: ()=>{}) {
    if (typeof EventSystem.queue[event] === "undefined") {
      EventSystem.queue[event] = [];
    }

    let id = EventSystem.idCounter;
    EventSystem.queue[event].push({ callback, id });
    EventSystem.idCounter++;
    return id;
  }
}

export default EventSystem;
