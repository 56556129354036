import React, { Component } from "react";
import EventSystem from "../../utils/EventSystem";
import styled, { css } from "styled-components";

export const Button = styled.button`
  border: 1px solid #6aba06;
  border-radius: 5px;
  padding: 8px 20px;
  user-select: none;
  outline: none;
  background-color: white;

  font-size: 12pt;
  font-family: "Raleway Black", sans-serif;
  margin: 5px 15px;
  text-align: center;
  transition: height 0.2s ease-in-out, background-color 100ms ease-in-out, color 100ms ease-in-out;
  color: #000000;

  &:hover, &:active {
    background-color: #64af05;
    cursor: pointer;
  }

  @media screen and (max-width: 1000px) {
    margin: 2px 5px;
    padding-left: 7px;
    padding-right: 7px;
    font-size: 12pt;
  }
`;

const OuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200000;
  transform: translateY(100%);
  backdrop-filter: blur(2px);
  transition: transform 500ms ease-in-out, background-color 300ms ease-in-out;

  ${({ opened }) =>
  opened === true &&
  css`
      transform: translateY(0);
      background-color: rgba(0, 0, 0, 0.15);
    `};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 800px) {
    align-items: flex-end;
    justify-content: flex-end;
    & > div > div {
      width: 100%;
    }
    & > div {
      width: 100%;
      justify-content: flex-end;
    }
  }
`;

const Panel = styled.div`
  width: 500px;
  min-height: 300px;
  max-height: 90%;
  background-color: rgb(255,255,255);
  padding: 20px;
  border-radius: 10px;
  color: rgb(17,17,17);
  overflow-y: auto;
  box-shadow: 0 0 10px 1px rgb(2, 2, 2);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  & > h1 {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 19pt;
    margin-bottom: 10px;
    width: 100%;
    color: rgb(24,24,24);
  }

  & > form {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    & > h4 {
      width: 100%;
      margin: 0;
    }
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    justify-content: flex-start;
  }
`;

const Title = styled.h1`
  width: 100%;
  font-size: 16pt;
  margin: 0;
  padding: 5px 5px 8px 5px;
`;

const Details = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`;

const ButtonSpec = styled(Button)`
  //background-color: #0b69d9;
  margin: 5px 10px;

  @media screen and (max-width: 800px) {
    margin: 5px 10px;
  }
`;

export default class ConfirmationModal extends Component {
  state = {
    showModal: false,
    title: "",
    text: "",
    cancelCb: undefined,
    proceedCb: undefined
  };

  cancel() {
    this.setState({ showModal: false });
    if (this.state.cancelCb) this.state.cancelCb();
  }

  proceed() {
    this.setState({ showModal: false });
    if (this.state.proceedCb) this.state.proceedCb();
  }

  componentDidMount() {
    window.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        this.cancel();
      }
    });
    EventSystem.subscribe(EventSystem.events.show_confirmation_modal, ({ title, text, yesText, noText, cancelCb, proceedCb }) => {
      this.setState({
        showModal: true,
        title,
        text,
        yesText,
        noText,
        cancelCb,
        proceedCb
      });
    });
  }

  render() {
    return (
      <OuterWrapper opened={this.state.showModal} onClick={() => this.cancel()}>
        <Panel onClick={(e) => e.stopPropagation()}>
          <Title>{this.state.title}</Title>
          <Details>{this.state.text}</Details>
          <Footer>
            {this.state.noText && this.state.noText.length > 0 &&
            <ButtonSpec variant="secondary" onClick={() => this.cancel()}>
              {this.state.noText}
            </ButtonSpec>
            }
            {this.state.yesText && this.state.yesText.length > 0 &&
            <ButtonSpec variant="primary" onClick={() => this.proceed()}>
              {this.state.yesText}
            </ButtonSpec>
            }
          </Footer>
        </Panel>
      </OuterWrapper>
    );
  }
}
