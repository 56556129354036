export class Shelf {
  id: number;
  enabled: boolean;
  x: number;
  y: number;
  width: number;
  height: number;
  empty: boolean;

  itemIDsandSKus: Array<{ value: string, key: string }>;

  constructor(x: number, y: number, width: number, height: number, id: number, enabled: boolean, empty: boolean) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.id = id;
    this.enabled = enabled;
    this.empty = empty;
    this.itemIDsandSKus = [];
  }
}
