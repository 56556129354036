import React, { Component } from "react";
import styled, { css } from "styled-components";
import { PageContainer } from "../components/PageContainer";
import { AuthAPI } from "../utils/api/AuthAPI";
import { withRouter } from "react-router-dom";
import Config from "../utils/Config";
import EventSystem from "../utils/EventSystem";
import { FaCartArrowDown, FaSignOutAlt, FiUsers } from "react-icons/all";
import ConfirmationModal from "../components/modals/ConfirmationModal";
import { Helmet } from "react-helmet";
import Profiles from "../components/home/Profiles";
import Orders from "../components/home/Orders";
import Shelves from "../components/home/Shelves";
import OrderDetailsModal from "../components/modals/OrderDetailsModal";
import beep_sound from "../assets/beep.mp3";
import error_sound from "../assets/error.mp3";
import ProductsSelectorModal from "../components/modals/ProductsSelectorModal";

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 60px);

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  
  @media screen and (max-width: 1000px){
    flex-direction: row;
    flex-wrap: wrap-reverse;
  }
`;

const SideMenu = styled.div`
  width: 20vw;
  max-width: 250px;
  height: 100%;

  background-color: white;
  padding: 0 10px 0 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 0 10px 1px gray;
  z-index: 2;
  
  @media screen and (max-width: 1000px){
    width: 100%;
    max-width: unset;
    height: auto;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding: 5px 0;
  }
`;

const MenuItem = styled.div`
  width: 100%;
  min-height: 25px;
  padding: 8px 0;

  margin-top: 10px;
  user-select: none;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  //background-color: #e9e9e9;
  border-radius: 10px;

  ${({ bottom }) => bottom && css`
    margin-top: auto;
    margin-bottom: 0;
  `}

  &>span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    & > svg {
      margin-left: 5px;
      width: 20%;
      font-size: 18pt;
    }
  }

  & > svg {
    margin-left: 5px;
    width: 20%;
    font-size: 18pt;
  }

  &:hover, &:active {
    background-color: #edf0f6;
    cursor: pointer;
  }

  ${({ selected }) => selected === true && css`
    background-color: #edf0f6;
    cursor: pointer;
  `};
    
  @media screen and (max-width: 1000px){
    width: auto;
    padding: 8px 10px;
    margin: 0 7px;
    & > svg{
      width: auto;
      margin-right: 5px;
    }
  }
`;

const DashBoardWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  
  @media screen and (max-width: 1000px){
    height: calc(100% - 60px);
    overflow-y: auto;
  }
`;

class HomePage extends Component {
  state = {
    loggedIn: false,
    profile: undefined,
    // loggedIn: false,
    // profile: null,
    selectedMenu: 0,
    mobile: window.innerWidth < 1000
  };

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.playSound, params => {
      if (params !== undefined && params.error === true) {
        this.playErrorNotificationSound();
      } else {
        this.playNotificationSound();
      }
    });

    window.addEventListener("resize", () => {
      this.setState({ mobile: window.innerWidth < Config.mobileMaxWidth });
    });

    AuthAPI.checkLogin(true, (loggedIn, profile) => {
      this.setState({ loggedIn, profile });
      if (loggedIn === false) {
        this.props.history.push("/bejelentkezes");
      }
    });

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ loggedIn }) => {
      if (loggedIn !== undefined) {
        this.setState({ loggedIn });
        if (!loggedIn)
          this.props.history.push("/bejelentkezes");
      }
    });
  }

  playNotificationSound() {
    const audioEl: HTMLAudioElement = document.getElementsByClassName("audio-element")[0];
    audioEl.currentTime = 0;
    // noinspection JSIgnoredPromiseFromCall
    audioEl.play();
  }

  playErrorNotificationSound() {
    const audioEl: HTMLAudioElement = document.getElementsByClassName("audio-element-error")[0];
    audioEl.currentTime = 0;
    // noinspection JSIgnoredPromiseFromCall
    audioEl.play();
  }

  selectMenu(menu) {
    this.setState({ selectedMenu: menu });
  }

  render() {
    return (
      <PageContainer>
        <Helmet>
          <title>Gold Dekor Csomagolás - Főoldal</title>
          <meta name="description" content="Gold Dekor Csomagolás - Főoldal"/>
        </Helmet>
        {this.state.loggedIn && (
          <Wrapper>
            <audio className="audio-element">
              <source src={beep_sound}/>
            </audio>
            <audio className="audio-element-error">
              <source src={error_sound}/>
            </audio>
            <ConfirmationModal/>
            <OrderDetailsModal/>
            <ProductsSelectorModal/>
            {/*<ExtrasSelector />*/}

            <SideMenu menuOpened={this.state.menuOpened}>
              <MenuItem selected={this.state.selectedMenu === 0} onClick={() => this.selectMenu(0)}>
                <FaCartArrowDown/>
                Rendelések
              </MenuItem>
              {this.state.profile && this.state.profile.admin === true &&
              <>
                <MenuItem selected={this.state.selectedMenu === 1} onClick={() => this.selectMenu(1)}>
                  <FiUsers/>
                  Felhasználók
                </MenuItem>
                {/*<MenuItem selected={this.state.selectedMenu === 2} onClick={() => this.selectMenu(2)}>*/}
                {/*  <FiLayers/>*/}
                {/*  Polcok*/}
                {/*</MenuItem>*/}
              </>
              }
              <MenuItem onClick={() => AuthAPI.logout()} bottom>
                <FaSignOutAlt/>
                Kijelentkezés
              </MenuItem>
            </SideMenu>
            <DashBoardWrapper>
              {this.state.selectedMenu === 0 && <Orders/>}
              {this.state.profile && this.state.profile.admin === true &&
              <>
                {this.state.selectedMenu === 1 && <Profiles/>}
                {this.state.selectedMenu === 2 && <Shelves/>}
              </>
              }
            </DashBoardWrapper>
          </Wrapper>
        )}
      </PageContainer>
    );
  }
}

export default withRouter(HomePage);
