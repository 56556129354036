import React, { Component } from "react";
import styled from "styled-components";

const Error = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: 120px;
  width: 100%;
  height: calc(100vh - 120px);

  font-size: 40pt;
  text-align: center;
  color: dimgrey;
  font-family: "Raleway", sans-serif;
`;

export default class ErrorPage extends Component {
  render() {
    return (
      <Error>
        Ooupsz! :( Az oldal nem található!
        <br />
        404-es hiba
      </Error>
    );
  }
}
