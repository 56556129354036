// document.addEventListener("textInput", function(e) {
//   if (e.data.length >= 6) {
//     console.log("IR scan textInput", e.data);
//     e.preventDefault();
//   }
// });

// let UPC = "";
// document.addEventListener("keypress", function(e) {
//   const textInput = e.key || String.fromCharCode(e.keyCode);
//   const targetName = e.target.localName;
//   let newUPC = "";
//   if (textInput && textInput.length === 1 && targetName !== "input") {
//     newUPC = UPC + textInput;
//
//     if (newUPC.length >= 6) {
//       console.log("barcode scanned:  ", newUPC);
//     }
//   }
// });

// setTimeout(()=>{
//   document.dispatchEvent(new KeyboardEvent('keyup', {code: 'KeyG',isComposing: true,key: 'G',location: 0,repeat: false}));
//   document.dispatchEvent(new KeyboardEvent('keyup', {code: 'KeyD',isComposing: true,key: 'D',location: 0,repeat: false}));
//   document.dispatchEvent(new KeyboardEvent('keyup', {code: 'Digit2',isComposing: true,key: '2',location: 0,repeat: false}));
//   document.dispatchEvent(new KeyboardEvent('keyup', {code: 'Digit0',isComposing: true,key: '0',location: 0,repeat: false}));
//   document.dispatchEvent(new KeyboardEvent('keyup', {code: 'Digit1',isComposing: true,key: '1',location: 0,repeat: false}));
//   document.dispatchEvent(new KeyboardEvent('keyup', {code: 'Digit0',isComposing: true,key: '0',location: 0,repeat: false}));
//   document.dispatchEvent(new KeyboardEvent('keyup', {code: 'Digit0',isComposing: true,key: '0',location: 0,repeat: false}));
//   document.dispatchEvent(new KeyboardEvent('keyup', {code: 'Digit5',isComposing: true,key: '5',location: 0,repeat: false}));
//   document.dispatchEvent(new KeyboardEvent('keyup', {code: 'Digit1',isComposing: true,key: '1',location: 0,repeat: false}));
// }, 2000);

class BarcodeScannerEvents {
  timeOut: number = 0;
  input: string = "";

  initialize() {
    document.addEventListener("keyup", e => this._keyup(e));
  }

  _keyup(e: KeyboardEvent) {
    if (!e || !e.key)
      return;

    // console.log(e);
    if (this.timeOut > 0) {
      clearTimeout(this.timeOut);
      if (e.key.match("^[0-9a-zA-Z.-öüóőúéáűíÖÜÓŐÚÉÁŰÍ_]{1}$")) {
        this.input += e.key;
        // console.log(e.key + " - ok");
      } //else
      //   console.log(e.key);
    }

    this.timeOut = setTimeout(() => {
      if (this.input.length < 5) {
        this.input = "";
        return;
      }
      document.dispatchEvent(new CustomEvent("barcodeScanned", { detail: this.input }));
      this.input = "";
    }, 20);
  }
}

let b = new BarcodeScannerEvents();
b.initialize();
