import EventSystem from "./EventSystem";
import Config from "./Config";
import { AuthAPI } from "./api/AuthAPI";
import { Product } from "../model/Product";
import { ShelvesAPI } from "./api/ShelvesAPI";
import { Shelf } from "../model/Shelf";

export default class ContextSystem {
  static data;

  static mobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  static mobileLayout = window.innerWidth <= Config.mobileMaxWidth;
  static profile = undefined;
  static loggedIn = false;
  static products: Array<Product> = [];
  static shelves: Array<Shelf> = [];
  static loadingProducts: boolean = false;

  // Unique ID for anything needed
  static uniqueID = 0;

  static getNextUniqueID() {
    this.uniqueID++;
    return this.uniqueID;
  }

  static interval;

  static init() {
    this.loadFromLocalStorage();
    console.log("Init ContextSystem");
    this.data = {
      mobileDevice: this.mobileDevice,
      mobileLayout: this.mobileLayout,
      profile: this.profile,
      loggedIn: this.loggedIn,
      products: this.products
    };
    this.reload();
    this.subscribe();
  }

  static loadFromLocalStorage() {
    //Nothing to load from localstorage
    // let sa = window.localStorage.getItem("selectedAddress");
    // if (sa && sa !== "undefined")
    //   this.selectedAddress = JSON.parse(sa);
  }

  static reload() {
    this.loadProfile();
    // this.loadProducts();
  }

  static loadProducts() {
    if (this.products && this.products.length > 0)
      return;

    if (this.loadingProducts)
      return;

    this.loadingProducts = true;
    ShelvesAPI.getProducts(({ error, products }) => {
      if (error !== 0) {
        this.loadingProducts = false;
        return;
      }
      this.products = products;
      this.loadingProducts = false;
      EventSystem.publish(EventSystem.events.contextSystemChanged, { products });
    });

    ShelvesAPI.getShelves(res => {
      if (res.error !== 0) {
        return;
      }
      let shelves: Array<Shelf> = res.shelves;
      this.shelves = shelves;
      EventSystem.publish(EventSystem.events.contextSystemChanged, { shelves });
    });
  }

  static loadProfile() {
    AuthAPI.checkLogin(true, (loggedIn, profile) => {
      this.loggedIn = loggedIn;
      this.profile = profile;
      EventSystem.publish(EventSystem.events.contextSystemChanged, { loggedIn, profile });
    });
  }

  static subscribe() {
    window.addEventListener("resize", () => {
      this.setMobileLayout();
    });
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, (data) => {
      let changed = false;
      for (let key in data) {
        // noinspection JSUnfilteredForInLoop
        if (this.data[key] !== data[key]) {
          changed = true;
        }
        // noinspection JSUnfilteredForInLoop
        this.data[key] = data[key];
      }
      if (changed)
        this.reload();
    });

    EventSystem.subscribe(EventSystem.events.authentication_changed, (auth) => {
      if (this.loggedIn !== auth) {
        this.reload();
      }

      if (auth === true) {
        this.loadProducts();
        // this.getBackups();
        if (this.interval)
          clearInterval(this.interval);
        // this.interval = setInterval(() => this.getBackups(), 15000);
      } else {
        clearInterval(this.interval);
      }
    });

    EventSystem.subscribe(EventSystem.events.urlChanged, () => {
      // this.reload();
    });
  }

  static setMobileLayout() {
    let newValue = window.innerWidth <= Config.mobileMaxWidth;
    if (newValue !== this.mobileLayout) {
      this.mobileLayout = newValue;
      EventSystem.publish(EventSystem.events.contextSystemChanged, { mobileLayout: newValue });
    }
  }
}
