import { CustomerGroup } from "./CustomerGroup";

export class OrderState {
  static NEW: number = 1;
  static PACKAGING: number = 2;
  static WAITING_FOR_CONFIRMATION: number = 3;
  static CONFIRMING: number = 4;
  static READY: number = 5;
  static DONE: number = 6;

  static getName(state: number): string {
    switch (state) {
      case this.NEW:
        return "Új";
      case this.PACKAGING:
        return "Feldolgozás alatt";
      case this.WAITING_FOR_CONFIRMATION:
        return "Összeszedett";
      case this.CONFIRMING:
        return "Csomagolás alatt";
      case this.READY:
        return "Futárnak átadva";
      case this.DONE:
        return "Kiküldve";
      default:
        return "Állapothiba...";
    }
  }

  static getNextState(currentState): number {
    let newState: number = -1;
    if (currentState === OrderState.NEW) {
      newState = OrderState.PACKAGING;
    } else if (currentState === OrderState.PACKAGING) {
      newState = OrderState.WAITING_FOR_CONFIRMATION;
    } else if (currentState === OrderState.WAITING_FOR_CONFIRMATION) {
      newState = OrderState.CONFIRMING;
    } else if (currentState === OrderState.CONFIRMING) {
      newState = OrderState.READY;
    } else if (currentState === OrderState.READY) {
      newState = OrderState.DONE;
    }
    return newState;
  }
}

export class OrderTotal {
  id: string;
  name: string;
  // noinspection JSUnusedGlobalSymbols
  sortOrder: number;
  type: string;
  value: number;
  // noinspection JSUnusedGlobalSymbols
  valueText: string;
}

export class OrderProductPrepQty {
  id: number;
  enabled: boolean;
  orderID: string;
  orderProductSKU: string;
  qty: number;


  constructor(orderID: string, orderProductSKU: string, qty: number) {
    this.orderID = orderID;
    this.orderProductSKU = orderProductSKU;
    this.qty = qty;
    this.enabled = true;
    this.id = -1;
  }
}

export class OrderProduct {
  // noinspection JSUnusedGlobalSymbols
  modelNumber: string;
  name: string;
  // noinspection JSUnusedGlobalSymbols
  originalPrice: number;
  gtin: string;
  price: number;
  // noinspection JSUnusedGlobalSymbols
  sku: string;
  // noinspection JSUnusedGlobalSymbols
  taxRate: number;
  total: number;
  stock1: number;
  // noinspection JSUnusedGlobalSymbols
  subtractStock: number;
  mainImage: string;
}

export class Order {
  comment: string;
  customerGroup: CustomerGroup;
  dateCreated: { value: string };
  innerId: string;
  date: Date;
  // noinspection JSUnusedGlobalSymbols
  email: string;
  firstname: string;
  id: string;
  // noinspection JSUnusedGlobalSymbols
  invoiceId: string;
  // noinspection JSUnusedGlobalSymbols
  invoiceIdPrefix: string;
  lastname: string;
  orderProducts: Array<OrderProduct>;
  // noinspection JSUnusedGlobalSymbols
  orderStatusID: string;
  // noinspection JSUnusedGlobalSymbols
  orderTotals: Array<OrderTotal>;
  // noinspection JSUnusedGlobalSymbols
  paymentAddress1: string;
  // noinspection JSUnusedGlobalSymbols
  paymentAddress2: string;
  // noinspection JSUnusedGlobalSymbols
  paymentAddressFormat: string;
  // noinspection JSUnusedGlobalSymbols
  paymentCity: string;
  // noinspection JSUnusedGlobalSymbols
  paymentCompany: string;
  // noinspection JSUnusedGlobalSymbols
  paymentCountryName: string;
  // noinspection JSUnusedGlobalSymbols
  paymentFirstname: string;
  // noinspection JSUnusedGlobalSymbols
  paymentLastname: string;
  // noinspection JSUnusedGlobalSymbols
  paymentMethodAfter: boolean;
  // noinspection JSUnusedGlobalSymbols
  paymentMethodCode: string;
  paymentMethodName: string;
  // noinspection JSUnusedGlobalSymbols
  paymentMethodTaxName: string;
  // noinspection JSUnusedGlobalSymbols
  paymentMethodTaxRate: number;
  // noinspection JSUnusedGlobalSymbols
  paymentPostcode: string;
  // noinspection JSUnusedGlobalSymbols
  paymentZoneName: string;
  phone: string;
  // noinspection JSUnusedGlobalSymbols
  pickPackPontShopCode: string;
  shippingAddress1: string;
  // noinspection JSUnusedGlobalSymbols
  shippingAddress2: string;
  shippingCity: string;
  // noinspection JSUnusedGlobalSymbols
  shippingCompany: string;
  // noinspection JSUnusedGlobalSymbols
  shippingCountryName: string;
  // noinspection JSUnusedGlobalSymbols
  shippingFirstname: string;
  // noinspection JSUnusedGlobalSymbols
  shippingLastname: string;
  // noinspection JSUnusedGlobalSymbols
  shippingMethodExtension: string;
  shippingMethodName: string;
  // noinspection JSUnusedGlobalSymbols
  shippingMethodTaxName: string;
  // noinspection JSUnusedGlobalSymbols
  shippingMethodTaxRate: number;
  shippingPostcode: string;
  // noinspection JSUnusedGlobalSymbols
  shippingReceivingPointId: string;
  // noinspection JSUnusedGlobalSymbols
  shippingZoneName: string;
  // noinspection JSUnusedGlobalSymbols
  taxNumber: string;
  // noinspection JSUnusedGlobalSymbols
  total: number;
}

export class OrderStatus {
  orderID: string;
  // noinspection JSUnusedGlobalSymbols
  profileID: number;
  orderState: { value: number, name: string };
  date: Date;
  comment: string;
  // noinspection JSUnusedGlobalSymbols
  automatic: boolean;
}
