import React, { Component } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { AuthAPI } from "../../utils/api/AuthAPI";
import ContextSystem from "../../utils/ContextSystem";
import { Helmet } from "react-helmet";
// import { SettingsAPI } from "../../utils/api/SettingsAPI";
// import { ProfilesAPI } from "../../utils/api/ProfileAPI";
import { AiOutlinePlus } from "react-icons/all";
import Modal from "../modals/Modal";
import { Button } from "../modals/ConfirmationModal";
import { ProfilesAPI } from "../../utils/api/ProfileAPI";


export const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;

  & > h2 {
    font-size: 20pt;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > svg{
    font-size: 20pt;
  }
  font-size: 12pt;
  
  &:hover, &:active{
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 20px;
`;

const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 5px;
  border: 1px solid #ebebeb;
  padding: 10px;

  & > h2 {
    font-size: 16pt;
    width: 100%;
    text-align: left;
  }
  
  & > inputwrapper{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
  }
  
  & > inputwrapper-checkbox{
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    & > input{
      padding: 3px;
      margin: 5px;
      outline: none;
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
    
    @media screen and (max-width: 1000px){
      & > inputwrapper{
        width: 90%;
      }
    }
  }
`;

const Input = styled.input`
  width: ${({ width }) => (width ? width : "70%")};
  padding: 5px 20px;
  margin: 10px 0;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;

  @media screen and (max-width: 600px) {
    width: ${({ width }) => (width ? width : "100%")};
  }

  &:focus {
    border-color: rgba(246,192,0,0.25);
    box-shadow: 0 0 0 0.2rem rgba(246,192,0,0.25);
  }
`;

const ButtonWrapperSpec = styled(ButtonWrapper)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ProfilesWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
`;

const Profile = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 7px 15px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 4px 1px #343434;
  
  @media screen and (max-width: 1000px){
    width: calc(100% - 30px);
  }
`;

export default class Profiles extends Component {
  state = {
    editOldPass: "",
    editNewPass: "",
    editAdmin: false,
    profiles: [],
    showModal: false,
    editRegEmail: "",
    editRegPass: ""
  };

  savePass() {
    if (this.state.editOldPass.length < 5 || this.state.editNewPass.length < 5) {
      this.setState({ editOldPass: "", editNewPass: "" });
      toast("A megadott jelszavak túl rövidek.");
      return;
    }
    AuthAPI.changePassword(this.state.editOldPass, this.state.editNewPass, (res) => {
      this.setState({ editOldPass: "", editNewPass: "" });
      if (res.error === 0) toast("Mentve");
    });
  }

  removeProfile(profileID) {
    if (this.state.profiles.length <= 1) {
      toast("Az utolsó felhasználót nem törölheted!");
      return;
    }
    ProfilesAPI.remove(profileID, res => {
      if (res.error === 0) {
        toast("Törölve!");
        let profiles = [];
        for (let profile of this.state.profiles) {
          if (profile.id !== profileID)
            profiles.push(profile);
        }
        this.setState({ profiles });
      }
    });
  }

  registerProfile() {
    this.setState({ showModal: true, editAdmin: false, editRegEmail: "", editRegPass: "" });
  }

  addProfile() {
    ProfilesAPI.register(this.state.editRegEmail, this.state.editRegPass, this.state.editAdmin, res => {
      if (res.error === 0) {
        toast("Profil hozzáadva!");
        this.reloadProfiles();
        this.setState({ showModal: false, editRegEmail: "", editRegPass: "", editAdmin: false });
      }
    });
  }

  // saveSettingRecords() {
  // SettingsAPI.saveFiles(this.state.editGoogleFileID, this.state.editSpartanCSVURL, res => {
  //   if (res.error === 0) {
  //     toast("Mentve.");
  //     this.reloadProfile();
  //   }
  // });
  // }

  // reloadSettings(){
  // SettingsAPI.getSettings(res => {
  //   if (res.error === 0) {
  //     for (let s of res.settings) {
  //       if (s.key === "fileID")
  //         this.setState({ editGoogleFileID: s.value });
  //       if (s.key === "spartanCSVURL")
  //         this.setState({ editSpartanCSVURL: s.value });
  //     }
  //   }
  // });
  // }

  componentDidMount() {
    this.reloadProfiles();
  }

  reloadProfiles() {
    ProfilesAPI.getProfiles(res => {
      if (res.error === 0) {
        this.setState({ profiles: res.profiles });
      }
    });
  }

  render() {
    return (
      <Wrapper>
        <Helmet>
          <title>Gold Dekor Csomagolás - Felhasználók</title>
          <meta name="description" content="Gold Dekor Felhasználók"/>
        </Helmet>
        <FormWrapper id={"settings-password-change-" + ContextSystem.getNextUniqueID()}>
          <h2>Jelszó megváltoztatása</h2>
          <Input
            id={"passwordCurrent"}
            value={this.state.editOldPass}
            type="password"
            autoComplete={"current-password"}
            placeholder="Jelenlegi jelszó"
            onChange={(e) => this.setState({ editOldPass: e.target.value })}
            width={"49%"}
          />
          <Input
            id={"passwordNew"}
            value={this.state.editNewPass}
            type="password"
            autoComplete={"new-password"}
            placeholder="Új jelszó"
            onChange={(e) => this.setState({ editNewPass: e.target.value })}
            width={"49%"}
          />
          <ButtonWrapperSpec>
            <Button onClick={() => this.savePass()}>Mentés</Button>
          </ButtonWrapperSpec>
        </FormWrapper>
        {/*<br/>*/}
        {/*<FormWrapper id={"settings-files-change-" + ContextSystem.getNextUniqueID()}>*/}
        {/*  <h2>Fájlok módosítása</h2>*/}
        {/*  <inputwrapper>*/}
        {/*    <label>Spartan CSV URL:</label>*/}
        {/*    <Input*/}
        {/*      id={"spartancsvhost"}*/}
        {/*      value={this.state.editSpartanCSVURL}*/}
        {/*      type="text"*/}
        {/*      autoComplete={"off"}*/}
        {/*      placeholder="Spartan CSV URL"*/}
        {/*      onChange={(e) => this.setState({ editSpartanCSVURL: e.target.value })}*/}
        {/*      width={"100%"}*/}
        {/*    />*/}
        {/*  </inputwrapper>*/}
        {/*  <inputwrapper>*/}
        {/*    <label>Árazó excel Google Drive File azonosító</label>*/}
        {/*    <Input*/}
        {/*      id={"googleDriveFileID"}*/}
        {/*      value={this.state.editGoogleFileID}*/}
        {/*      type="text"*/}
        {/*      autoComplete={"off"}*/}
        {/*      placeholder="Árazó excel fileID"*/}
        {/*      onChange={(e) => this.setState({ editGoogleFileID: e.target.value })}*/}
        {/*      width={"100%"}*/}
        {/*    />*/}
        {/*  </inputwrapper>*/}
        {/*  <ButtonWrapperSpec>*/}
        {/*    <Button onClick={() => this.saveSettingRecords()}>Mentés</Button>*/}
        {/*  </ButtonWrapperSpec>*/}
        {/*</FormWrapper>*/}
        <ProfilesWrapper>
          <TopWrapper>
            <h2>Profilok</h2>
            <ButtonWrapperSpec onClick={() => this.registerProfile()}>
              <AiOutlinePlus/>
              <span>Új profil</span>
            </ButtonWrapperSpec>
          </TopWrapper>
          {this.state.profiles.map((p, i) =>
            <Profile key={i}>
              <div>{p.email}</div>
              <div>{p.admin ? "Admin" : "Felhasználó"}</div>
              <Button onClick={() => this.removeProfile(p.id)}>Törlés</Button>
            </Profile>
          )}
        </ProfilesWrapper>
        <Modal show={this.state.showModal} size={"lg"} onEscapeKeyDown={() => this.setState({ showModal: false })}>
          <Modal.Header>
            <Modal.Title>Új profil készítése</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormWrapper id={"settings-register-profile-" + ContextSystem.getNextUniqueID()}>
              <inputwrapper>
                <label>Regisztrációs email:</label>
                <Input
                  id={"regemail"}
                  value={this.state.editRegEmail}
                  type="text"
                  autoComplete={"email"}
                  placeholder="Email cím"
                  onChange={(e) => this.setState({ editRegEmail: e.target.value })}
                  width={"100%"}
                />
              </inputwrapper>
              <inputwrapper>
                <label>Jelszó</label>
                <Input
                  id={"regpass"}
                  value={this.state.editRegPass}
                  type="password"
                  autoComplete={"new-password"}
                  placeholder="Jelszó"
                  onChange={(e) => this.setState({ editRegPass: e.target.value })}
                  width={"100%"}
                />
              </inputwrapper>
              <inputwrapper-checkbox>
                <label>Admin jogosultságok</label>
                <Input
                  id={"admin"}
                  value={this.state.editAdmin}
                  checked={this.state.editAdmin}
                  type="checkbox"
                  autoComplete={"off"}
                  placeholder=""
                  onChange={(e) => this.setState({ editAdmin: e.target.checked })}
                  width={"100%"}
                />
              </inputwrapper-checkbox>
            </FormWrapper>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => this.addProfile()}>
              Hozzáadás
            </Button>
            <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
              Bezárás
            </Button>
          </Modal.Footer>
        </Modal>
      </Wrapper>
    );
  }
}
