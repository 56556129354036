import "./Initializer";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { css } from "glamor";
import { AuthAPI } from "./utils/api/AuthAPI";
import EventSystem from "./utils/EventSystem";
import { Helmet } from "react-helmet/es/Helmet";
import ErrorPage from "./pages/ErrorPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import Header from "./components/header/Header";
import ScrollToTop from "./utils/ScrollToTop";
import "./css/toastify.css";
import "./css/main.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./utils/Barcode";

class App extends React.Component {
  state = {
    cookie: false,
    authenticated: false
  };

  componentDidMount() {
    this.setState({
      cookie: window.localStorage.getItem("cookie") === "1"
    });
    AuthAPI.checkLogin(false, (authenticated) => this.setState({ authenticated }));
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ loggedIn }) => {
      if (loggedIn !== undefined)
        this.setState({ authenticated: loggedIn });
    });
  }

  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <Header/>
          <Helmet>
            <title>Gold Dekor Csomagolás</title>
            <meta name="description" content="Gold Dekor Csomagolás oldal"/>
          </Helmet>
          <ToastContainer
            autoClose={3000}
            draggablePercent={50}
            position={toast.POSITION.TOP_RIGHT}
            toastClassName={css({
              background: "#ffffff",
              color: "#1f1f1f",
              boxShadow: "0 0 3px 1px #121212"
            })}
            progressClassName={css({
              height: "5px",
              background: "#ffb500"
            })}
          />
          <Switch>
            <Route path={"/bejelentkezes"} component={() => <LoginPage redirect={true}/>} exact/>
            <Route path={"/"} component={HomePage} exact/>
            <Route component={ErrorPage}/>
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default App;
