import styled from "styled-components";
import React, { Component } from "react";
import Modal from "./Modal";
import { Button } from "./ConfirmationModal";
import EventSystem from "../../utils/EventSystem";
import { Product } from "../../model/Product";
import ContextSystem from "../../utils/ContextSystem";
import AsyncSelect from "react-select/async";
import { BsTrashFill } from "react-icons/all";

const ListWrapper = styled.div`
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;
  margin-bottom: 15px;
`;

const ListLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
`;

const ProductDiv = styled.div`
  padding: 5px 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ProductDeleteButton = styled.div`
  margin-right: 8px;
`;

const Select = styled(AsyncSelect)`
  width: 90%;
`;

export default class ProductsSelectorModal extends Component {
  state: {
    showModal: boolean,
    productsSelected: Array<string>,
    products: Array<Product>,
    productsOptions: Array<{ value: string, label: string }>,
    shelfID: number,
  } = {
    showModal: false,
    productsSelected: [],
    products: [],
    productsOptions: [],
    shelfID: -1
  };

  reloadOptions(products: Array<Product>) {
    let productsOptions: Array<{ value: string, label: string }> = [];

    for (let product of products) {
      productsOptions.push({ label: product.name + " (" + product.sku + ")", value: product.id });
    }

    this.setState({ productsOptions });
  }

  componentWillUnmount() {
    document.removeEventListener("barcodeScanned", v => this.handleBarcodeScanned(v));
  }

  componentDidMount() {
    document.addEventListener("barcodeScanned", v => this.handleBarcodeScanned(v));
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ products }) => {
      if (!this.state.showModal)
        return;

      if (products !== undefined) {
        this.reloadOptions(products);
        this.setState({ products });
      }
    });
    EventSystem.subscribe(EventSystem.events.openProductsSelector, ({ shelfID, selectedProductIDs }) => {
      this.reloadOptions(ContextSystem.products);
      this.setState({
        shelfID,
        showModal: true,
        products: ContextSystem.products,
        productsSelected: selectedProductIDs
      });
    });
  }

  render() {
    return (
      <Modal show={this.state.showModal} size={"lg"} onEscapeKeyDown={() => this.handleEscapeKeyDown()}>
        <Modal.Header>
          <Modal.Title>Termékek hozzákötése a polchoz.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Kiválaszott termékek</h5>
          <span>(Termék gyors kiválasztásához használd a vonalkód olvasót!)</span>
          <ListWrapper>
            <ListLayout>
              {this.state.productsSelected.map((productId: string, i) => {
                let product = this.getProduct(productId);
                if (!product) {
                  return (
                    <React.Fragment key={i}/>
                  );
                }
                return (
                  <ProductDiv key={i}>
                    {product.sku} | {product.name}
                    <ProductDeleteButton>
                      <BsTrashFill
                        onClick={() => this.removeProduct(product.id)}
                      />
                    </ProductDeleteButton>
                  </ProductDiv>
                );
              })}
            </ListLayout>
          </ListWrapper>
          <Select
            cacheOptions
            placeholder={"Termék keresése..."}
            defaultOptions={[]}
            noOptionsMessage={() => "Nincs kiválasztható termék"}
            loadOptions={(inputValue, cb) => cb(this.getFilteredOptions(inputValue))}
            onChange={(e) => this.addProduct(e)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => this.handleSaveButton()}>
            Kész
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  handleSaveButton() {
    EventSystem.publish(EventSystem.events.productsSelected, {
      shelfID: this.state.shelfID,
      productsSelected: this.state.productsSelected
    });
    this.setState({ showModal: false });
  }

  handleEscapeKeyDown() {
    this.setState({ showModal: false });
  }

  getProduct(productId: string, productSKU: string): Product {
    for (let product of this.state.products) {
      if (productId) {
        if (product.id === productId)
          return product;
      }
      if (productSKU) {
        if (product.sku === productSKU)
          return product;
      }
    }

    return null;
  }

  removeProduct(id) {
    let productsSelected: Array<string> = [];
    for (let productId of this.state.productsSelected) {
      if (productId !== id)
        productsSelected.push(productId);
    }

    this.setState({ productsSelected });
  }

  addProduct({ value: productId }) {
    let productsSelected: Array<string> = this.state.productsSelected;
    for (let prodIdSelected of productsSelected) {
      if (prodIdSelected === productId) {
        EventSystem.publish(EventSystem.events.show_confirmation_modal, {
          title: "Termék már a polcon van!",
          text: "Egy terméket csak egyszer lehet felvinni a polcra. (Ettől függetlenül több polcon is elérhető lehet!)",
          yesText: "Ok",
          noText: ""
        });
        return;
      }
    }
    productsSelected.push(productId);

    this.setState({ productsSelected });
  }

  getFilteredOptions(inputValue) {
    let options: Array<{ value: string, label: string }> = [];
    let k = 0;
    for (let product of this.state.productsOptions) {
      if (product.label.toLowerCase().includes(inputValue.toLowerCase())) {
        options.push(product);
        k++;
      }
      if (k > 14)
        break;
    }
    return options;
  }

  handleBarcodeScanned(value: CustomEvent) {
    if (!this.state.showModal)
      return;

    //value.detail should be the sku
    if (!value)
      return;

    let product = this.getProduct(undefined, value.detail);
    if (!product) {
      EventSystem.publish(EventSystem.events.show_confirmation_modal, {
        title: "Termék nem található!",
        text: "A szkennelt termék vonalkód nem található a rendszerben!",
        yesText: "Ok",
        noText: ""
      });
      return;
    }

    this.addProduct({ value: product.id });
  }
}
