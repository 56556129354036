import React from "react";
import Scanner from "react-webcam-barcode-scanner";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default class BarcodeScannerComponent extends React.Component<{}> {
  ref: HTMLDivElement;

  state: {
    width: number,
    height: number,
  } = {
    width: 500,
    height: 500
  };

  componentDidMount() {
    this.updateDimensions();
  }

  updateDimensions() {
    if (!this.ref || (this.ref && this.ref.clientWidth === this.state.width))
      return;

    this.setState({
      width: this.ref.offsetWidth,
      height: this.ref.offsetHeight
    });
  }

  render() {
    return (
      <Wrapper ref={r => {
        this.ref = r;
        this.updateDimensions();
      }}>
        <Scanner
          width={this.state.width}
          height={this.state.height}
          onUpdate={(err, result) => {
            if (result) {
              document.dispatchEvent(new CustomEvent("barcodeScanned", { detail: result.getText() }));
            }
          }}
        />
      </Wrapper>
    );
  }
}
