import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "babel-polyfill";
import fill from "./utils/DatePolyfill";
import DatePolyfill from "./utils/DatePolyfill";
import { polyfill } from "es6-promise";
import { toast } from "react-toastify";
import ContextSystem from "./utils/ContextSystem";

let initialized = false;

function initialize() {
  fill();
  //polyfill-ing other languages (e.g.: es6 can work on ie as well)
  polyfill();
  toast.configure();
  // eslint-disable-next-line no-unused-vars
  initialized = true;
  reloadAtMidnight();
  DatePolyfill();
  ContextSystem.init();
}

// eslint-disable-next-line no-extend-native
Array.prototype.remove = function() {
  let what,
    a = arguments,
    L = a.length,
    ax;
  while (L && this.length) {
    what = a[--L];
    while ((ax = this.indexOf(what)) !== -1) {
      this.splice(ax, 1);
    }
  }
  return this;
};

function reloadAtMidnight() {
  let now = new Date();
  let midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 5, 0, 0);

  let milliseconds = midnight - now;

  if (milliseconds < 0) {
    milliseconds += 86400000; // it's after midnight, try 10am tomorrow.
  }
  setTimeout(function() {
    window.location.reload(true);
    reloadAtMidnight();
  }, milliseconds);
}

initialize();
