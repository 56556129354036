import { withRouter } from "react-router-dom";
import { Component } from "react";
import EventSystem from "./EventSystem";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps, prevState, snapShot) {
    if (this.props.location !== prevProps.location) {
      try {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } catch (e) {
        window.scrollTo(0, 0);
      }
      EventSystem.publish(EventSystem.events.urlChanged, {});
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
