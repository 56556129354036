import APICaller from "../APICaller";
import EmailValidator from "../EmailValidator";
import { toast } from "react-toastify";
import EventSystem from "../EventSystem";

let authenticated = null;
let profile = null;
let checkingAuthentication = false;

function login(email, pass, cb) {
  if (!EmailValidator(email)) {
    return false;
  }
  APICaller.ownFetch(false, "PUT", "/", { email, pass }, (res) => {
    let result = JSON.parse(res);
    if (result.error !== 0) {
      toast("Kérem próbáld újra.");
    } else {
      if (result.authenticated) {
        window.localStorage.setItem("usertoken", result.token);
        authenticated = true;
        profile = result.profile;
        if (cb) cb(true);
        EventSystem.publish(EventSystem.events.authentication_changed, true);
        return;
      } else {
        toast("Email cím vagy jelszó hibás.");
      }
    }
    authenticated = false;
    if (cb) cb(false);
    EventSystem.publish(EventSystem.events.authentication_changed, false);
  });
}

function checkLogin(quite, cb) {
  if (authenticated != null) {
    if (!authenticated) {
      if (cb) cb(authenticated, null);
      return;
    } else if (authenticated && profile != null) {
      if (cb) cb(authenticated, profile);
      return;
    }
  }

  if (checkingAuthentication) {
    setTimeout(() => checkLogin(quite, cb), 100);
    return;
  }

  checkingAuthentication = true;

  APICaller.ownFetch(quite, "POST", "/", {}, (res) => {
    let oldA = authenticated;
    let result = JSON.parse(res);

    if (result.error === 0) {
      profile = result.profile;
      authenticated = true;
    } else {
      profile = null;
      authenticated = false;
    }
    if (cb) cb(authenticated, profile);
    checkingAuthentication = false;

    if (oldA !== authenticated) EventSystem.publish(EventSystem.events.authentication_changed, authenticated);
  });
}

function logout() {
  APICaller.ownFetch(true, "DELETE", "/", {});
  authenticated = false;
  profile = null;
  window.localStorage.removeItem("usertoken");
  EventSystem.publish(EventSystem.events.authentication_changed, false);
}

function changePassword(oldPass, newPass, cb) {
  APICaller.ownFetch(
    false,
    "POST",
    "/profile/password",
    {
      old: oldPass,
      new: newPass
    },
    (data) => {
      let res = JSON.parse(data);
      if (cb)
        cb(res);
    }
  );
}

export const AuthAPI = {
  login,
  checkLogin,
  logout,
  changePassword
};
