import React, { Component } from "react";
import styled from "styled-components";
import { PageContainer } from "../components/PageContainer";
import EmailValidator from "../utils/EmailValidator";
import { toast } from "react-toastify";
import { AuthAPI } from "../utils/api/AuthAPI";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet/es/Helmet";
import EventSystem from "../utils/EventSystem";

const Wrapper = styled.div`
  width: 100%;
  min-height: ${({ minheight }) => (minheight ? minheight : "70vh")};

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`;

const LoginPanel = styled.div`
  width: 500px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px 1px dimgray;

  & > h1 {
    margin-bottom: 30px;
    font-family: "Segoe UI Light", sans-serif;
    font-size: 28pt;
  }

  & > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    & > p {
      margin: 0;
      padding: 0 0 0 5px;
      width: 70%;
      color: #101010;
      text-align: left;
      &:hover {
        cursor: pointer;
        color: #f33242;
      }
    }
  }

  @media screen and (max-width: 600px) {
    width: 90%;
  }
`;

export const Input = styled.input`
  width: ${({ width }) => (width ? width : "70%")};
  padding: ${({ padding }) => (padding ? padding : "5px 20px")};
  margin: ${({ margin }) => (margin ? margin : "10px 0")};
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;

  @media screen and (max-width: 600px) {
    width: ${({ width, mwidth }) => (mwidth ? mwidth : (width ? width : "100%"))};
  }

  &:focus {
    border-color: rgba(147, 28, 32, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(147, 28, 32, 0.25);
  }
`;

const Button = styled.div`
  border: 1px solid #6a6502;
  border-radius: 5px;
  padding: 8px 20px;

  font-size: 12pt;
  font-family: "Raleway Black", sans-serif;
  margin: 5px 20% 15px 20%;
  text-align: center;
  transition: height 0.2s ease-in-out, background-color 100ms ease-in-out, color 100ms ease-in-out;
  color: #000000;

  &:hover,
  &:active {
    background-color: #e3d90c;
    cursor: pointer;
  }

  @media screen and (max-width: 600px) {
    margin: 0;
    padding-left: 2px;
    padding-right: 2px;
    font-size: 12pt;
  }
`;

class LoginPage extends Component {
  eventIDs = [];

  state = {
    editEmail: "",
    editPass: "",
    showLostPassModal: false,
    editLostEmail: ""
  };

  loginOnEnter(e) {
    if (e.keyCode === 13)
      this.login();
  }

  login() {
    if (!EmailValidator(this.state.editEmail)) {
      toast("Email cím helytelen!");
      return;
    }
    AuthAPI.login(this.state.editEmail, this.state.editPass, (authenticated) => this.redirect(authenticated));
  }

  redirect(authenticated) {
    if (this.props.redirect) {
      if (authenticated)
        this.props.history.push("/");
      // if (document.referrer === "") this.props.history.push("/");
      // else this.props.history.goBack();
    }
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs)
      EventSystem.unsubscribe(eventID);

    this.eventIDs = [];
  }

  componentDidMount() {
    this.eventIDs = [];

    AuthAPI.checkLogin(false, (authenticated) => {
      this.redirect(authenticated);
    });
    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({ loggedIn }) => {
      if (loggedIn !== undefined)
        this.redirect(loggedIn);
    });
    this.eventIDs.push(eid);
  }

  render() {
    return (
      <PageContainer>
        <Helmet>
          <title>Gold Dekor Csomagolás - Bejelentkezés</title>
          <meta name="description" content="Gold Dekor Csomagolás - Bejelentkezés"/>
        </Helmet>
        <Wrapper>
          <LoginPanel>
            <h1>Bejelentkezés</h1>
            <form>
              <Input
                autocomplete={"email"}
                value={this.state.editEmail}
                onKeyUp={(e) => this.loginOnEnter(e)}
                type="email"
                placeholder="E-mail cím"
                onChange={(e) => this.setState({ editEmail: e.target.value })}
              />
              <Input
                autocomplete={"password"}
                value={this.state.editPass}
                onKeyUp={(e) => this.loginOnEnter(e)}
                type="password"
                placeholder="Jelszó"
                onChange={(e) => this.setState({ editPass: e.target.value })}
              />
              <Button onClick={() => this.login()}>Belépés</Button>
            </form>
          </LoginPanel>
        </Wrapper>
      </PageContainer>
    );
  }
}

export default withRouter(LoginPage);
