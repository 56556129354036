const Config = {
  TEST: false,
  DEBUG: false,
  mobileMaxWidth: 800,
  backendURL: "https://backend.golddekorpackaging.opthink.com"
};

if (Config.DEBUG && !Config.TEST) {
  Config.backendURL = "http://localhost:3016";
}
if (Config.TEST) {
  Config.backendURL = "https://backend.test.golddekorpackaging.opthink.com";
  if (Config.DEBUG) {
    Config.backendURL = "http://localhost:3015";
  }
}

export default Config;
