import APICaller from "../APICaller";

function getProfiles(cb: (result: Object)=>{}) {
  APICaller.ownFetch(false, "POST", "/profiles", {}, (res) => {
    let result = JSON.parse(res);
    if (cb) cb(result);
  });
}

function register(email: string, pass: string, admin: boolean, cb: (result: Object)=>{}) {
  APICaller.ownFetch(false, "PUT", "/profile", { email, pass, admin }, (res) => {
    let result = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

function remove(profileID: number, cb: (result: Object)=>{}) {
  APICaller.ownFetch(false, "DELETE", "/profile", { profileID }, (res) => {
    let result = JSON.parse(res);
    if (cb)
      cb(result);
  });
}

export const ProfilesAPI = {
  getProfiles,
  register,
  remove
};
